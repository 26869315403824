// codeowner: trust
import {useQuery} from '@tanstack/react-query';
import api from 'v2/core/api';

type Params = {
  encoded_url: string;
};

type UrlRiskResponse = {
  url_risk: string;
};

function useGetUrlRisk(params: Params) {
  const url = `/url_risk`;

  return useQuery([url, params], () => {
    return api.get<UrlRiskResponse>(url, 'url_risk', {params});
  });
}

export default useGetUrlRisk;
