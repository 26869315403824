// codeowner: trust
import {useEffect} from 'react';
import LoadingUrlRiskResponse from 'v2/redirect/components/loadingUrlRiskResponse';
import MaliciousLinkWarning from 'v2/redirect/components/maliciousLinkWarning';
import UnableToDetermineRisk from 'v2/redirect/components/unableToDetermineRisk';
import useGetUrlRisk from 'v2/redirect/useGetUrlRisk';

function UrlRedirectPage() {
  const {encoded_url} = window.PAGEDATA;
  const url = window.atob(encoded_url);
  const {data: response, isLoading, isError} = useGetUrlRisk({encoded_url});

  useEffect(() => {
    if (response && response.data.url_risk === 'safe') {
      window.location.replace(url);
    }
  }, [response, url]);

  if (isLoading) {
    return <LoadingUrlRiskResponse />;
  }

  if (isError) {
    return <UnableToDetermineRisk url={url} />;
  }

  const {data} = response;
  switch (data.url_risk) {
    case 'malicious':
      return <MaliciousLinkWarning />;
    case 'safe':
      //render the loading indicator until useEffect redirects the user
      return <LoadingUrlRiskResponse />;
    default:
      return <UnableToDetermineRisk url={url} />;
  }
}

export default UrlRedirectPage;
