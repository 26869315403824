// codeowner: ont
import $ from 'jquery';
import * as URI from 'uri-js';
import type {$TSFixMeAny, $TSFixMeFunction} from 'v2/core/util/flowCompat';

function init() {
  initializeNavToggle();
  loadAsyncModulesIfAny();
  programRequirementsScroll();
}

function initializeNavToggle() {
  $('#nav-opener-link').on('click', () => {
    $('body').toggleClass('nav-active');
  });
}

function programRequirementsScroll() {
  $('a.p-bookmark').on('click', function (e) {
    const contentId = $(this).attr('href');
    if (contentId != null) {
      const content: JQuery<HTMLElement> = $(document).find(contentId);
      const scroll_animate_time = 300;
      const scrollTop = content.offset()?.top || 0;
      $('html,body').animate({scrollTop}, scroll_animate_time);
      e.preventDefault();
    }
    e.preventDefault();
  });
}

/*
 * This function serves as a marketing router which asynchronously loads
 * additional modules depending on the URL.
 */
function loadAsyncModulesIfAny() {
  const parsedURL = URI.parse(window.location.href);
  const path = parsedURL.path;
  let asyncModule: Promise<{executeModule: $TSFixMeFunction}> | null = null;

  const findInPath = (term: $TSFixMeAny) => {
    if (path && path.includes(term)) {
      return path;
    }
  };

  switch (path) {
    case '/':
    case '/stories':
    case '/rise':
    case '/approach/learning-experience':
    case '/approach/learning-experience/mentoring':
    case '/approach/learning-experience/projects':
    case '/join-us/program':
    case '/privacy-center':
      // @ts-expect-error no types defined
      import('lity/dist/lity'); // eslint-disable-line import/dynamic-import-chunkname
      // @ts-expect-error no types defined
      import('lity/dist/lity.css'); // eslint-disable-line import/dynamic-import-chunkname
      break;
    case '/join-us/program-requirements':
      asyncModule = import(
        /* webpackChunkName: "marketing_program_requirements" */ 'v2/marketing/programRequirements'
      );
      break;
    case '/research':
      asyncModule = import(
        /* webpackChunkName: "marketing_research_campaign_form" */ 'v2/marketing/researchCampaignForm'
      );
      break;
    case '/demo/guided-tours':
      asyncModule = import(
        /* webpackChunkName: "marketing_demo_homepage_youtube_video" */ 'v2/marketing/demoHomepageYoutubeVideo'
      );
      break;
    case findInPath('/privacy-center/program-agreement'):
    case findInPath('/privacy-center/data-privacy-addendum'):
    case findInPath('/privacy-center/security'):
    case findInPath('/privacy-center/partner-terms-of-service'):
    case findInPath('/privacy-center/user-agreement'):
    case findInPath('/privacy-center/privacy-policy'):
      asyncModule = import(
        /* webpackChunkName: "marketing_privacy_center" */ 'v2/marketing/printButton'
      );
      break;
    default:
      return;
  }

  if (asyncModule) {
    asyncModule.then((module) => module.executeModule());
  }
}

export default init;
