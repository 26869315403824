// codeowner: dfn
import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {IndexRoute, Route} from 'v2/core/routing/routesWithFetchData';
import PlatformDocRoutes from 'v2/platform_docs/platformDocRoutes';
import StudentsApp from 'v2/students/app';

import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

const ProgressRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_progress" */ 'v2/students/sdl/progressPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'My Progress',
  name: 'progress',
  path: '/my/progress',
});

const ResourcesRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "students_resources_index" */ 'v2/students/resources/page'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'resources-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "students_learning_strategies" */ 'v2/students/resources/learningStrategiesPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Strategies',
      name: 'learning_strategies',
      path: 'learning_strategies',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "students_learning_strategy" */ 'v2/students/resources/learningStrategyPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Strategy',
      name: 'learning_strategy',
      path: 'learning_strategy/:vanityKey',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "students_habits_dimension" */ 'v2/students/components/habitsDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habits Dimension',
      name: 'habits-dimension',
      path: 'habits/dimension/:dimensionId/:tab',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "students_habit" */ 'v2/students/components/oldOrNewHabit'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habit',
      name: 'habit',
      path: 'habit/:habitId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "students_habits" */ 'v2/students/components/oldOrNewHabits'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habits of Success',
      name: 'habits',
      path: 'habits',
    }),
  ],
  label: 'My Resources',
  name: 'resources',
  path: 'resources',
};

const ProgressForYearRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_progress" */ 'v2/students/sdl/progressPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'My Progress for Year',
  name: 'progress-for-year',
  path: '/my/progress/year/:filter',
});

const WeekRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "students_week" */ 'v2/students/sdl/weekPage')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'My Week',
  name: 'week',
  path: '/my/week',
});

const ThisYearRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_this_year" */ 'v2/students/thisyear/page'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'My Year',
  name: 'this-year',
  path: '/my/year/:filter',
});

const StudentProjectAgendaRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_project_plan" */ 'v2/students/project/views/projectPlanPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Project Plan',
  name: 'project_plan',
  path: '/my/projects/:projectId/plans',
});

const StudentProjectOverviewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_project_overview" */ 'v2/students/project/views/projectOverviewPageV4'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Project Overview',
  name: 'project_overview',
  path: '/my/projects/:projectId/overview',
  analyticsPageProperties(routeParams) {
    return {pagePathParams: {projectId: routeParams.projectId}};
  },
});

const StudentProjectFeedbackRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_project_feedback" */ 'v2/students/project/views/studentProjectFeedbackPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Student Project Feedback',
  name: 'student_project_feedback',
  path: '/my/projects/:projectId/assignment',
  analyticsPageProperties(routeParams) {
    return {pagePathParams: {projectId: routeParams.projectId}};
  },
});

// Note that focus areas are "know_dos" on the backend
const FocusAreasRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_focus_area" */ 'v2/students/focusarea/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Focus Area Overview',
  name: 'focusArea',
  path: '/my/focusareas/:areaId',
  analyticsPageProperties(routeParams) {
    return {
      pagePathParams: {
        areaId: routeParams.areaId,
      },
    };
  },
});

const FocusAreaOverviewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_focusarea_show" */ 'v2/teachers/focusareas/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Focus Area Overview',
  name: 'focusarea_show',
  path: '/my/focusareas/:focusAreaId',
});

const AssessmentTakeRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_assessment_take" */ 'v2/students/assessmentTake/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Assessment Take',
  name: 'assessmentTake',
  // Redirect
  onEnter: ({params}, replace) =>
    replace(`/my/assessment_takes/${params.assessmentTakeId}/q/1`),
  path: '/my/assessment_takes/:assessmentTakeId',
});

const AssessmentTakeV2Route = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_assessment_take_v_2" */ 'v2/students/assessmentTake/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Assessment Take',
  name: 'assessmentTakeV2',
  // Redirect
  onEnter: ({params}, replace) =>
    replace(`/my/assessment_takes_v2/${params.assessmentTakeId}/q/1`),
  path: '/my/assessment_takes_v2/:assessmentTakeId',
});

const AssessmentTakeQuestionRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_assessment_take_question" */ 'v2/students/assessmentTake/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'assessmentTakeQuestion',
  path: '/my/assessment_takes/:assessmentTakeId/q/:questionId',
  analyticsPageProperties(routeParams) {
    return {
      pagePathParams: {
        assessmentTakeId: routeParams.assessmentTakeId,
        questionId: routeParams.questionId,
      },
    };
  },
});

const AssessmentTakeQuestionV2Route = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_assessment_take_question_v_2" */ 'v2/students/assessmentTake/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'assessmentTakeQuestionV2',
  path: '/my/assessment_takes_v2/:assessmentTakeId/q/:questionId',
});

const ProjectAssessmentTakeQuestionRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_assessment_take_question_with_project" */ 'v2/students/assessmentTake/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'assessmentTakeQuestionWithProject',
  path: '/my/projects/:projectId/assessment_takes/:assessmentTakeId/q/:questionId',
});

const CourseRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_course" */ 'v2/students/course/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Course Overview',
  name: 'course',
  path: '/my/courses/:courseId/v2',
});

const LearningToolRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_learning_tool" */ 'v2/students/components/learningToolPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Learning Tool',
  name: 'lti-tool',
  path: '/my/learning_tools/:toolName',
});

const StudentTosRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_tos" */ 'v2/students/tos/studentTosHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Terms of Service',
  name: 'students-tos',
  path: 'students/welcome',
});

const ActingTeacherTosRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_tos" */ 'v2/teachers/tos/teacherTosHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'teachers-tos',
  path: '/teachers/terms_and_conditions',
});

const ActingParentTosRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_tos" */ 'v2/parents/tos/parentTosHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parents-tos',
  path: 'parents/terms_and_conditions',
});

const GoalsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "students_goals" */ 'v2/students/goals/handler')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Goals',
  name: 'goals',
  path: '/my/goals',
});

const EnrollmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_enroll" */ 'v2/students/sections/enrollment'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'enroll',
  path: '/enroll',
});

const ErrorRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "students_error" */ 'v2/students/errorHandler')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Error Page',
  name: 'error',
  path: '/v2/students/error',
});

const CollegeIntroRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_intro" */ 'v2/students/college/intro/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Intro',
  name: 'college-intro',
  path: 'intro',
});

const CollegeSummaryRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_summary" */ 'v2/students/college/summary/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Summary',
  name: 'college-summary',
  path: 'summary',
});

const CollegeCoursesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_courses" */ 'v2/students/college/courses/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Courses',
  name: 'college-courses',
  path: 'courses',
});

const CollegeGradesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_grades" */ 'v2/students/college/grades/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Grades',
  name: 'college-grades',
  path: 'grades',
});

const CollegeLettersRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_letters" */ 'v2/students/college/letters_of_recommendation/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Letters',
  name: 'college-letters',
  path: 'letters_of_recommendation',
});

const CollegeScoresRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_scores" */ 'v2/students/college/scores/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Scores',
  name: 'college-scores',
  path: 'test_scores',
});

const CollegeEssayRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_college_essay" */ 'v2/students/college/essay/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'College Essay',
  name: 'college-essay',
  path: 'essay',
});

const CheckForUnderstandingRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_check_for_understanding" */ 'v2/students/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Check For Understanding',
  name: 'check-for-understanding',
  path: '/check_for_understanding/:activityId',
});

const EndOfUnitTakeRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_end_of_unit_assessment_take" */ 'v2/students/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Take End of Unit Assessment',
  name: 'end-of-unit-assessment-take',
  path: '/my/end_of_unit_assessment/take/:activityId/fp/:projectAssetId',
});

const PreAssessmentTakeRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_pre_assessment_take" */ 'v2/students/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Take Pre-Assessment',
  name: 'pre-assessment-take',
  path: '/my/pre_assessment/take/:activityId/fp/:projectAssetId',
});

const EmbeddableLearnosityAssessmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_doc_embeddable_assessment" */ 'v2/students/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Doc Embeddable Learnosity Assessment',
  name: 'doc-embeddable-assessment',
  path: '/doc_embeddable_assessment/:activityId',
});

const RubricRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "rubric_index" */ 'v2/rubrics/studentRubricPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'rubric-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "rubric_dimension" */ 'v2/rubrics/studentRubricDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Rubric Dimension',
      name: 'rubric-dimension',
      path: 'dimension/:dimensionId',
    }),
  ],
  label: 'Rubric',
  name: 'rubric',
  path: 'rubric',
};

// College Routes
const CollegeRoute = Route.createRouteFromObject({
  childRoutes: [
    CollegeIntroRoute,
    CollegeSummaryRoute,
    CollegeCoursesRoute,
    CollegeGradesRoute,
    CollegeLettersRoute,
    CollegeScoresRoute,
    CollegeEssayRoute,
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "students_college_index" */ 'v2/students/college/indexHandler'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    label: 'My College',
    name: 'college-index',
  }),
  label: 'My College',
  name: 'college',
  path: '/my/college',
});

const AnnouncementsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "students_announcements" */ 'v2/students/announcements'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'My Announcements',
  name: 'announcements',
  path: '/my/announcements',
});

const StudentsRootRoutes: ReactRouterRootRoutesType = {
  appType: AppTypes.STUDENTS,
  childRoutes: [
    AnnouncementsRoute,
    CollegeRoute,
    ThisYearRoute,
    ProgressRoute,
    ProgressForYearRoute,
    WeekRoute,
    StudentProjectAgendaRoute,
    StudentProjectFeedbackRoute,
    StudentProjectOverviewRoute,
    FocusAreasRoute,
    FocusAreaOverviewRoute,
    AssessmentTakeRoute,
    AssessmentTakeV2Route,
    AssessmentTakeQuestionRoute,
    AssessmentTakeQuestionV2Route,
    CheckForUnderstandingRoute,
    ProjectAssessmentTakeQuestionRoute,
    CourseRoute,
    LearningToolRoute,
    StudentTosRoute,
    GoalsRoute,
    EnrollmentRoute,
    ErrorRoute,
    EndOfUnitTakeRoute,
    PreAssessmentTakeRoute,
    EmbeddableLearnosityAssessmentRoute,
    RubricRoutes,
    PlatformDocRoutes,
    ResourcesRoutes,
    ActingTeacherTosRoute,
    ActingParentTosRoute,
  ],
  component: StudentsApp,
  name: 'app',
  path: '/',
};

export default StudentsRootRoutes;
