import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {IndexRoute, Route} from 'v2/core/routing/routesWithFetchData';
import type {$TSFixMeFunction} from 'v2/core/util/flowCompat';
import GuestApp from 'v2/guests/guestApp';
import PlatformDocRoutes from 'v2/platform_docs/platformDocRoutes';

import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

// This component is shared between two routes. To avoid creating two
// separate webpack chunks we use a common method to require.ensure it.
function requireCurriculumOverviewComponent(done: $TSFixMeFunction) {
  import(
    /* webpackChunkName: "guests_curriculum_dimension_overview_handler" */ 'v2/guests/curriculum/curriculumDimensionOverviewHandler'
  )
    .then(({default: Handler}) => done(null, Handler))
    .catch(handleImportError(done));
}

const GuestCourseRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "guests_course" */ 'v2/guests/courses/courseHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'course',
      path: ':courseId',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "guests_courses" */ 'v2/guests/curriculum/curriculumHandler'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'courses',
  }),
  name: 'curriculum',
  path: 'courses',
};

const GuestProjectRoute = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "guests_project_overview_for_teacher" */ 'v2/guests/projects/projectOverviewPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Project Overview',
      name: 'project-overview-for-teacher',
      path: 'overview_for_teacher',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "guests_project_plans_for_teacher" */ 'v2/guests/projects/projectPlansPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Project Plans',
      name: 'project-plans-for-teacher',
      path: 'plans_for_teacher',
    }),
  ],
  path: 'projects/:projectId',
  name: 'project',
};

const GuestFocusAreaRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "guests_focusarea_show" */ 'v2/guests/focusAreas/focusAreaHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'focusarea_show',
  path: 'focusareas/:focusAreaId',
});

const GuestGltRoutes = Route.createRouteFromObject({
  getComponent(location, done) {
    requireCurriculumOverviewComponent(done);
  },
  name: 'glt',
  path: 'glt/:grade',
});

const GuestSubjectRoutes = Route.createRouteFromObject({
  getComponent(location, done) {
    requireCurriculumOverviewComponent(done);
  },
  name: 'subject',
  path: 'subjects/:subjectId',
});

const RubricRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "rubric_index" */ 'v2/rubrics/guestRubricPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    label: 'Rubric',
    name: 'rubric-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "rubric_dimension" */ 'v2/rubrics/guestRubricDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Rubric Dimension',
      name: 'rubric-dimension',
      path: 'dimension/:dimensionId',
    }),
  ],
  name: 'rubric',
  path: 'rubric',
};

// this page is referenced in the curriculum
const GuestCheckForUnderstandingRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "guests_check_for_understanding" */ 'v2/guests/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Check For Understanding',
  name: 'check-for-understanding',
  path: '/check_for_understanding/:activityId',
});

const GuestMathUnitAssessmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "guests_end_of_unit_assessment" */ 'v2/guests/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Math Unit Assessment',
  name: 'end-of-unit-assessment',
  path: '/end_of_unit_assessment/:activityId/fp/:projectAssetId',
});

const GuestEmbeddableLearnosityAssessmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "guests_doc_embeddable_assessment" */ 'v2/guests/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Doc Embeddable Learnosity Assessment',
  name: 'doc-embeddable-assessment',
  path: '/doc_embeddable_assessment/:activityId',
});

const GuestRoutes = {
  childRoutes: [
    GuestCheckForUnderstandingRoute,
    GuestCourseRoutes,
    GuestEmbeddableLearnosityAssessmentRoute,
    GuestFocusAreaRoute,
    GuestGltRoutes,
    GuestMathUnitAssessmentRoute,
    GuestProjectRoute,
    GuestSubjectRoutes,
  ],
  path: 'guest',
  name: 'guest',
};

const exported: ReactRouterRootRoutesType = {
  appType: AppTypes.GUESTS,

  childRoutes: [GuestRoutes, PlatformDocRoutes, RubricRoutes],

  component: GuestApp,
  name: 'app',
  path: '/',
};

export default exported;
export {GuestApp as component};

export const {appType, childRoutes, name, path} = exported;
