import {createContext} from 'react';
import {CLICK_LINK, UNKNOWN} from 'v2/core/logging/analytics/constants';
import {trackClickedBreadcrumb} from 'v2/core/logging/analytics/track';
import {
  EDUCATOR_DATA_TOOL,
  IMPLEMENTATION_METRICS_SURFACES,
} from 'v2/teachers/schoolData/constants/schoolDataAnalytics';

const getImplementationSubmetric = (pathName: string) => {
  if (pathName.includes('sdl-usage')) {
    return 'sdl_usage';
  } else if (pathName.includes('pbl-usage')) {
    return 'pbl_usage';
  } else if (pathName.includes('mentoring')) {
    return 'mentoring';
  }
  return '';
};

const getImplementationSubmetricSurface = (
  pathName: string,
  type: string,
): string => {
  const submetric = getImplementationSubmetric(pathName);
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly gradeLevel: { readonly sdl_usage: "sdd_self_directed_learning_grade_level"; readonly pbl_usage: "sdd_project_based_learning_grade_level"; readonly mentoring: "sdd_mentoring_grade_level"; }; ... 4 more ...; readonly teacherCourse: { ...; }; }'.
  return IMPLEMENTATION_METRICS_SURFACES[type][submetric];
};

const OBFUSCATED_TEXT = '*****';

const safeTitles = [
  '1:1 Mentoring Implementation',
  'Cog Skills',
  'Courses',
  'Math Units',
  'Math Units Average',
  'Mentoring',
  'No Overdue Projects',
  'Overdue Projects',
  'Power Focus Areas',
  'Project Based Learning Implementation',
  'Project Based Learning',
  'Projects',
  'Self-Directed Learning Implementation',
  'Self-Directed Learning',
  'Student Incompletes',
  'Student Outcomes',
  'Student Platform Activity',
];

const titlePIICheck = (title: string): string => {
  if (safeTitles.includes(title) || title.startsWith('Grade ')) {
    return title;
  }
  return OBFUSCATED_TEXT;
};

const safeBreadcrumbs = [
  // Breadcrumbs for pilot SDD
  'Course Overview',
  'Grade Level Overview',
  'Home',
  'Mentor Overview',
  'Mentoring Data',
  'Project Based Learning Data',
  'Self Directed Learning Data',
  'Student Platform Activity',
  'Teacher Overview',

  // Breadcrumbs for legacy SDD
  'School Data',
  'Student Outcomes',
];

const breadcrumbPIICheck = (title: string) => {
  if (safeBreadcrumbs.includes(title) || title.startsWith('Grade ')) {
    return title;
  }
  return OBFUSCATED_TEXT;
};

type SchoolDataAnalyticsContextType = {
  surface: string;
  dashboardVersion: string;
};

const SchoolDataAnalyticsContext =
  createContext<SchoolDataAnalyticsContextType>({
    surface: UNKNOWN,
    dashboardVersion: UNKNOWN,
  });
const SchoolDataAnalyticsContextProvider = SchoolDataAnalyticsContext.Provider;
const SchoolDataAnalyticsContextConsumer = SchoolDataAnalyticsContext.Consumer;

const trackBreadcrumb = (
  surface: string,
  dashboardVersion: string,
  title: string,
) => {
  // Need to make sure we aren't logging PII in the title.
  const trackTitle = breadcrumbPIICheck(title);

  trackClickedBreadcrumb({
    dashboardVersion,
    eventTarget: trackTitle,
    eventType: CLICK_LINK,
    productArea: EDUCATOR_DATA_TOOL,
    surface,
  });
};

export {
  titlePIICheck,
  getImplementationSubmetric,
  getImplementationSubmetricSurface,
  SchoolDataAnalyticsContext,
  SchoolDataAnalyticsContextConsumer,
  SchoolDataAnalyticsContextProvider,
  trackBreadcrumb,
};
