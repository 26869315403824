// codeowner: trust
import React from 'react';
import App from 'v2/core/components/app';
import type {AppProps} from 'v2/core/components/app';
import UrlRedirectPage from 'v2/redirect/urlRedirectPage';

/**
 * Top-level app for the redirecting users from malicious urls
 */
class TrajectRedirectApp extends React.Component<AppProps> {
  render() {
    return (
      <App
        className="redirect"
        params={this.props.params}
        routes={this.props.routes}
      >
        <UrlRedirectPage />
      </App>
    );
  }
}

export default TrajectRedirectApp;
