// codeowner: trust
import EmptyState from 'v2/core/components/emptyState';
import LoadingIndicator from 'v2/core/components/loadingIndicator';

const LoadingUrlRiskResponse = () => {
  return (
    <EmptyState
      align="center"
      headerText="Hang tight, we're making sure this link is safe for you"
      verticalAlign="center"
    >
      <LoadingIndicator bsSize="large" />
    </EmptyState>
  );
};

export default LoadingUrlRiskResponse;
