// codeowner: sdt

import queryString from 'query-string';
import platformDocTerms from 'v2/admin/platform_docs/platformDocTerms';
import AdminRoutes from 'v2/admin/routes';
import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {
  fetchDataForRoute,
  IndexRoute,
  Route,
} from 'v2/core/routing/routesWithFetchData';
import type {$TSFixMeAny, $TSFixMeFunction} from 'v2/core/util/flowCompat';
import PlatformDocRoutes from 'v2/platform_docs/platformDocRoutes';
import TeachersApp from 'v2/teachers/app';
import DataRoutes from 'v2/teachers/routes-data';

import * as SDD_CONSTANTS from 'v2/teachers/schoolData/constants/schoolDataAnalytics';
import {
  getImplementationSubmetric,
  getImplementationSubmetricSurface,
} from 'v2/teachers/schoolData/util/schoolDataAnalyticsUtils';

import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

function handleProjectChange(
  prevState: $TSFixMeAny,
  nextState: $TSFixMeAny,
  replace: $TSFixMeAny,
  done: $TSFixMeFunction,
) {
  const prevQuery = prevState.location.query;
  const nextQuery = nextState.location.query;

  const stoppedAssigning =
    !!parseInt(prevQuery.assign, 10) && !parseInt(nextQuery.assign, 10);

  const stoppedEditing =
    !!parseInt(prevQuery.edit, 10) && !parseInt(nextQuery.edit, 10);

  // Re-fetch data if we're leaving the assign/edit state.
  if (stoppedAssigning || stoppedEditing) {
    fetchDataForRoute(nextState, replace, done);
  } else {
    done();
  }
}

// This component is shared between two routes. To avoid creating two
// separate webpack chunks we use a common method to require.ensure it.
function requireCurriculumOverviewComponent(done: $TSFixMeFunction) {
  import(
    /* webpackChunkName: "teachers_curriculum_dimension_overview_handler" */ 'v2/teachers/curriculum/curriculumDimensionOverviewHandler'
  )
    .then(({default: Handler}) => done(null, Handler))
    .catch(handleImportError(done));
}

// v3 routes, without the '/v2' prefix
const ProjectRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_edit" */ 'v2/teachers/projects/views/edit/page'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Edit Project',
      name: 'project-edit',
      onChange: handleProjectChange,
      path: ':projectId/edit',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_learners" */ 'v2/teachers/projects/views/learners/page'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Project Learners',
      name: 'project-learners',
      onChange: handleProjectChange,
      path: ':projectId/learners',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_plans_for_curriculum" */ 'v2/teachers/projects/views/plans/page'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {pagePathParams: {projectId: routeParams.projectId}};
      },
      label: 'Project Plans',
      name: 'project-plans-for-curriculum',
      onChange: handleProjectChange,
      path: ':projectId/plans_for_curriculum',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_plans_for_teacher" */ 'v2/teachers/projects/views/plans/page'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {pagePathParams: {projectId: routeParams.projectId}};
      },
      label: 'Project Plans',
      name: 'project-plans-for-teacher',
      onChange: handleProjectChange,
      path: ':projectId/plans_for_teacher',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_overview_for_curriculum" */ 'v2/teachers/projects/views/projectOverviewPageV4'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Project Overview for Curriculum',
      name: 'project-overview-for-curriculum',
      onChange: handleProjectChange,
      path: ':projectId/overview_for_curriculum',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_overview_for_teacher" */ 'v2/teachers/projects/views/projectOverviewPageV4'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {pagePathParams: {projectId: routeParams.projectId}};
      },
      analyticsPageName: (url) => {
        const queryParams = queryString.parse(url.search);
        if (queryParams.checkpoint !== undefined) {
          return 'project-overview-for-teacher-checkpoint';
        } else if (queryParams.finalProduct !== undefined) {
          return 'project-overview-for-teacher-finalProduct';
        } else {
          return 'project-overview-for-teacher-overview';
        }
      },
      label: 'Project Overview for Teacher',
      name: 'project-overview-for-teacher',
      onChange: handleProjectChange,
      path: ':projectId/overview_for_teacher',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_project_students_v_3" */ 'v2/teachers/projects/views/facilitation/page'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageName: (url) => {
        const queryParams = queryString.parse(url.search);
        if (queryParams.assetId !== undefined) {
          return 'project-feedback-for-teacher-asset';
        } else {
          return 'project-feedback-for-teacher-overview';
        }
      },
      analyticsPageProperties(routeParams) {
        return {pagePathParams: {projectId: routeParams.projectId}};
      },
      label: 'Teacher Project Page Feedback Tab',
      name: 'project-students-v3',
      onChange: handleProjectChange,
      path: ':projectId/students',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teacher_project_feedback" */ 'v2/teachers/projects/views/facilitation/page/teacherProjectFeedbackPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Project Feedback Page',
      name: 'teacher_project_feedback',
      path: ':projectId/feedback/:assignmentAssetId',
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            projectId: routeParams.projectId,
            assignmentAssetId: routeParams.assignmentAssetId,
          },
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teacher_project_bulk_feedback" */ 'v2/teachers/projects/views/facilitation/page/teacherProjectBulkFeedbackPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Project Bulk Feedback',
      name: 'teacher_project_bulk_feedback',
      path: ':projectId/bulk_feedback',
    }),
  ],
  label: 'Projects',
  name: 'projects-v3',
  path: 'projects',
};

// Note that focus areas are "know_dos" on the backend
const FocusAreaRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_focusarea_show" */ 'v2/teachers/focusareas/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Focus Area Overview',
      name: 'focusarea_show',
      path: ':focusAreaId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_focusarea_diff" */ 'v2/teachers/focusareas/diff/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'focusarea_diff',
      path: ':focusAreaId/diff',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_focusarea_diff_all" */ 'v2/teachers/focusareas/diff/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'focusarea_diff_all',
      path: ':focusAreaId/diff/all',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_focusarea_diff_downstream" */ 'v2/teachers/focusareas/diff/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'focusarea_diff_downstream',
      path: ':focusAreaId/diff_downstream/type/:downstreamType/id/:downstreamId',
    }),
  ],
  label: 'Focus Areas',
  name: 'focusareas',
  path: 'focusareas',
};

const EducatorLabsRoute = IndexRoute.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "teachers_labs" */ 'v2/teachers/labs/indexPage')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Labs',
  name: 'labs',
  path: 'labs',
});

const EducatorLabsCourseComposerRoute = IndexRoute.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_labs_course_composer" */ 'v2/teachers/labs/courseComposer'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Labs Course Composer',
  name: 'labs Course Composer',
  path: 'course_composer',
});

const EducatorLabsRecommendationsRoute = IndexRoute.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_labs_recommendations" */ 'v2/teachers/labs/recommendations'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Labs Recommendations',
  name: 'labs Recommendations',
  path: 'recommendations',
});

const EducatorToolsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_educator_tools" */ 'v2/teachers/resources/indexPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Educator Tools',
  name: 'educator_tools',
  path: 'educator_tools',
});

const ResourcesRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_resources_index" */ 'v2/teachers/resources/indexPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'resources-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_accessibility_resource_bank" */ 'v2/teachers/resources/accessibilityResourceBank'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Accessibility Resource Bank',
      name: 'accessibility_resource_bank',
      path: 'accessibility-resource-bank',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_accessibility_strategy" */ 'v2/teachers/resources/accessibilityStrategyPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Accessibility Resource',
      name: 'accessibility_strategy',
      path: 'accessibility/:vanityKey',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learning_strategies" */ 'v2/teachers/resources/learningStrategiesPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Strategies',
      name: 'learning_strategies',
      path: 'learning_strategies',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learning_strategy" */ 'v2/teachers/resources/learningStrategyPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Strategy',
      name: 'learning_strategy',
      path: 'learning_strategy/:vanityKey',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_habits" */ 'v2/teachers/components/oldOrNewHabits'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habits of Success',
      name: 'habits',
      path: 'habits',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_habits" */ 'v2/teachers/components/habitsDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habits Dimension',
      name: 'habits-dimension',
      path: 'habits/dimension/:dimensionId/:tab',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_habit" */ 'v2/teachers/components/oldOrNewHabit'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Habit',
      name: 'habit',
      path: 'habit/:habitId',
    }),
  ],
  label: 'Resources Page',
  name: 'resources',
  path: 'resources',
};

const LearnRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_admin" */ 'v2/teachers/learn/teacherLearningAdminHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Admin Page',
      name: 'learn-admin',
      path: 'admin',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_resource" */ 'v2/teachers/learn/pdResource'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            resourceId: routeParams.resourceId,
          },
        };
      },
      label: 'PD Resource',
      name: 'learn-resource',
      path: 'resources/:resourceId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_collection_event" */ 'v2/teachers/learn/teacherLearningEvent'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Event',
      name: 'learn-event',
      path: 'events/:eventId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_session" */ 'v2/teachers/learn/teacherLearningSessionHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            sessionId: routeParams.sessionId,
          },
        };
      },
      label: 'Learning Session',
      name: 'learn-session',
      path: 'sessions/:sessionId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_collection_event" */ 'v2/teachers/learn/teacherLearningCollectionHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Collection',
      name: 'learn-collection',
      path: 'collections/:collectionId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_search" */ 'v2/teachers/learn/searchResultsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Space Search',
      name: 'learn-search',
      path: 'search',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_learn_super_search" */ 'v2/teachers/learn/superSearchResultsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Learning Space Super Search',
      name: 'learn-super-search',
      path: 'super-search',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_learn_index" */ 'v2/teachers/learn/teacherLearningHandler'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'learn-index',
  }),
  label: 'Teacher Learning',
  name: 'learn',
  path: 'learn',
};

const MentoringRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_mentoring_agenda" */ 'v2/teachers/mentoring/mentoringHome'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Mentoring Agenda',
  name: 'mentoring-agenda',
  path: 'mentoring_agenda',
});

const MockStudentViewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_mock_student_view" */ 'v2/teachers/mockStudentView/mockStudentViewPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Mock Student View',
  name: 'mock-student-view',
  path: 'mock_student_view',
});

const UserRoleSurveyViewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_user_role_survey_view" */ 'v2/teachers/tos/userRoleSurveyPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'User Role Definition Survey',
  name: 'user-role-survey',
  path: 'user_role_survey',
});

const FoundationsCaseloadRoute = Route.createRouteFromObject({
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_foundations" */ 'v2/teachers/foundations/caseloadPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
  }),
  label: 'Foundations Case Load',
  name: 'foundations-caseload',
  path: 'foundations_caseload',
});

const SchoolDataLegacyRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/schoolDataHomePage

          /* webpackChunkName: "teachers_school_data_home" */ 'v2/teachers/schoolData/schoolDataHomePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'School Data Home',
      name: 'school-data-home',
      path: ':id/home',
      analyticsPageName: 'viewed_sdd_home_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.HOME,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/schoolData

          /* webpackChunkName: "teachers_school_data" */ 'v2/teachers/schoolData/schoolDataHomePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'School Data',
      name: 'school-data',
      path: ':id/site_overview',
      analyticsPageName: 'viewed_student_outcomes_overview_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.STUDENT_OUTCOMES_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_map_quintile" */ 'v2/teachers/schoolDataLegacy/mapQuintile'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Map Quintile',
      name: 'map-quintile',
      path: ':id/map_quintile',
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            id: routeParams.id,
          },
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_sbac_report" */ 'v2/teachers/schoolDataLegacy/sbacReport'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'SBAC Report',
      name: 'sbac_report',
      path: ':id/sbac_report',
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            id: routeParams.id,
          },
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/gradeLevelOverview

          /* webpackChunkName: "teachers_grade_level_overview" */ 'v2/teachers/schoolData/gradeLevelOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Overview',
      name: 'grade-level-overview',
      path: ':id/grade_level_overview',
      analyticsPageName: 'viewed_grade_level_overview_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/mentorOverview

          /* webpackChunkName: "teachers_signals_mentor_overview" */ 'v2/teachers/schoolData/mentorOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Overview',
      name: 'signals-mentor-overview',
      path: ':id/mentor_overview',
      analyticsPageName: 'viewed_mentor_overview_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/teacherOverview

          /* webpackChunkName: "teachers_signals_teacher_overview" */ 'v2/teachers/schoolData/teacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Overview',
      name: 'signals-teacher-overview',
      path: ':id/teacher_overview',
      analyticsPageName: 'viewed_teacher_overview_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/courseOverview

          /* webpackChunkName: "teachers_signals_course_overview" */ 'v2/teachers/schoolData/courseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Overview',
      name: 'signals-course-overview',
      path: ':id/course_overview',
      analyticsPageName: 'viewed_course_overview_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/mentorDetails

          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/mentorDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Details',
      name: 'mentor-details',
      path: ':id/mentor_details/:mentor_id',
      analyticsPageName: 'viewed_mentor_detail_v1',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add mentor_id here for PII reasons
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/teacherDetails

          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/teacherDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Details',
      name: 'teacher-details',
      path: ':id/teacher_details/:teacher_id',
      analyticsPageName: 'viewed_teacher_detail_v1',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add teacher_id here for PII reasons
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/courseTeacherOverview

          /* webpackChunkName: "teachers_course_teacher_overview" */ 'v2/teachers/schoolData/courseDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Teacher Overview',
      name: 'course-teacher-overview',
      path: ':id/course_teacher_overview/:course_id',
      analyticsPageName: 'viewed_course_detail_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            course_id: routeParams.course_id,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/gradeLevelDetails

          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/gradeLevelDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Details',
      name: 'grade-level-details',
      path: ':id/grade_level_details/:grade_level',
      analyticsPageName: 'viewed_grade_level_detail_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            grade_level: routeParams.grade_level,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/courseDetails

          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/courseDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Details',
      name: 'course-details',
      path: ':id/course_details/:teacher_id/:course_id',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add teacher_id here for PII reasons
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            course_id: routeParams.course_id,
            id: routeParams.id,
          },
        };
      },
    }),
    // START USAGE DATA ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/gradeLevelOverview

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/gradeLevelOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Usage Data Overview',
      name: 'signals-usage-grade-level-overview',
      path: ':id/usage/grade_level_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_grade_level_v1';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'gradeLevel',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/mentorOverview

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/mentorOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Usage Data Overview',
      name: 'signals-usage-mentor-overview',
      path: ':id/usage/mentor_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_mentor_v1';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'mentor',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/teacherOverview

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/teacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Usage Data Overview',
      name: 'signals-usage-teacher-overview',
      path: ':id/usage/teacher_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_teacher_v1';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'teacher',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/courseOverview

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/courseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Usage Data Overview',
      name: 'signals-usage-course-overview',
      path: ':id/usage/course_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_course_v1';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'course',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/teacherCourseOverview

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/teacherCourseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher-Course Usage Data Overview',
      name: 'signals-usage-teacher-course-overview',
      path: ':id/usage/teacher_overview/:teacher_id/course_overview/:submetric_group',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add teacher_id for PII reasons
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/usage/courseTeacherOverview'

          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/courseTeacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course-Teacher Usage Data Overview',
      name: 'signals-usage-course-teacher-overview',
      path: ':id/usage/course_teacher_overview/:course_id/:submetric_group',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            course_id: routeParams.course_id,
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
        };
      },
    }),
    // END USAGE DATA ROUTES
    // START MENTORING DATA ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/mentoring/mentorOverview

          /* webpackChunkName: "teachers_signals_mentoring" */ 'v2/teachers/schoolData/implementation/mentorOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Mentoring Data Overview',
      name: 'signals-mentoring-mentor-overview',
      path: ':id/mentoring/mentor_overview',
      analyticsPageName: 'viewed_sdd_mentoring_mentor_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING_MENTOR,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/mentoring/gradeLevelOverview

          /* webpackChunkName: "teachers_signals_mentoring" */ 'v2/teachers/schoolData/implementation/gradeLevelOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Mentoring Data Overview',
      name: 'signals-mentoring-grade-level-overview',
      path: ':id/mentoring/grade_level_overview',
      analyticsPageName: 'viewed_sdd_mentoring_grade_level_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING_GRADE_LEVEL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/mentoringDetails

          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/implementation/mentorDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentoring Details',
      name: 'mentoring-details',
      path: ':id/mentoring/details/:mentor_id',
      analyticsPageName: 'sdd_mentoring_mentor_details_v1',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add mentor_id for PII reasons
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING_MENTOR_DETAIL,
        };
      },
    }),
    // END MENTORING DATA ROUTES
    // LAUNCH METRICS ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_overview_looker" */ 'v2/teachers/schoolData/launchMetricsOverviewLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Looker',
      name: 'launch-metrics-overview-looker',
      path: ':id/launch_metrics/overview/looker',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_prepare_teachers_looker" */ 'v2/teachers/schoolData/launchMetricsPrepareTeachersLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Prepare Teachers Looker',
      name: 'launch-metrics-prepare-teachers-looker',
      path: ':id/launch_metrics/prepare/teachers/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_TEACHER_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_prepare_course_sections_looker" */ 'v2/teachers/schoolData/launchMetricsPrepareCourseSectionsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Prepare Course Sections Looker',
      name: 'launch-metrics-prepare-course-sections-looker',
      path: ':id/launch_metrics/prepare/teachers/sections_list/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_LIST_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_kick_off_teachers_looker" */ 'v2/teachers/schoolData/launchMetricsKickOffTeachersLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Kick Off Teachers Looker',
      name: 'launch-metrics-kick-off-teachers-looker',
      path: ':id/launch_metrics/kick_off/teachers/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_TEACHER_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_kick_off_course_sections_looker" */ 'v2/teachers/schoolData/launchMetricsKickOffCourseSectionsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Kick Off Course Sections Looker',
      name: 'launch-metrics-kick-off-course-sections-looker',
      path: ':id/launch_metrics/kick_off/teachers/sections/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_kick_off_students_looker" */ 'v2/teachers/schoolData/launchMetricsKickOffStudentsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Kick Off Students Looker',
      name: 'launch-metrics-kick-off-students-looker',
      path: ':id/launch_metrics/kick_off/sections/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_implement_teachers_looker" */ 'v2/teachers/schoolData/launchMetricsImplementTeachersLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Implement Teachers Looker',
      name: 'launch-metrics-implement-teachers-looker',
      path: ':id/launch_metrics/implement/teachers/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_TEACHER_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_implement_course_sections_looker" */ 'v2/teachers/schoolData/launchMetricsImplementCourseSectionsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Implement Course Sections Looker',
      name: 'launch-metrics-implement-course-sections-looker',
      path: ':id/launch_metrics/implement/teachers/sections/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_implement_students_looker" */ 'v2/teachers/schoolData/launchMetricsImplementStudentsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Implement Students Looker',
      name: 'launch-metrics-implement-students-looker',
      path: ':id/launch_metrics/implement/sections/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_implement_completed_mentor_checkin_looker" */ 'v2/teachers/schoolData/launchMetricsImplementCompletedMentorCheckinLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Implement Completed Mentor Checkin',
      name: 'launch-metrics-implement-completed-mentor-checkin-looker',
      path: ':id/launch_metrics/implement/received_first_checkin/teachers/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_wrap_up_teachers_looker" */ 'v2/teachers/schoolData/launchMetricsWrapUpTeachersLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Wrap Up Teachers Looker',
      name: 'launch-metrics-wrap-up-teachers-looker',
      path: ':id/launch_metrics/wrap_up/teachers/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_TEACHER_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_wrap_up_course_sections_looker" */ 'v2/teachers/schoolData/launchMetricsWrapUpCourseSectionsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Wrap Up Course Sections Looker',
      name: 'launch-metrics-wrap-up-course-sections-looker',
      path: ':id/launch_metrics/wrap_up/teachers/sections/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_wrap_up_students_looker" */ 'v2/teachers/schoolData/launchMetricsWrapUpStudentsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Wrap Up Students Looker',
      name: 'launch-metrics-wrap-up-students-looker',
      path: ':id/launch_metrics/wrap_up/sections/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_wrap_up_passed_pfa_looker" */ 'v2/teachers/schoolData/launchMetricsWrapUpPassedPFALooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Wrap Up PFA Looker',
      name: 'launch-metrics-wrap-up-passed-pfa-looker',
      path: ':id/launch_metrics/completed_pfa/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_implement_attempted_pfa_looker" */ 'v2/teachers/schoolData/launchMetricsImplementAttemptedPFALooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Implement Attempted PFA Looker',
      name: 'launch-metrics-implement-attempted-pfa-looker',
      path: ':id/launch_metrics/attempted_pfa/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "launch_metrics_prepare_assigned_mentor_looker" */ 'v2/teachers/schoolData/launchMetricsPrepareAssignedMentorLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Prepare Assigned Mentor Looker',
      name: 'launch-metrics-prepare-assigned-mentor-looker',
      path: ':id/launch_metrics/assigned_mentor/students/looker/:filter',
      analyticsPageName: 'viewed_launch_metrics_looker',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/launchMetrics

          /* webpackChunkName: "teachers_launch_metrics" */ 'v2/teachers/schoolData/launchMetricsOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics',
      name: 'launch-metrics',
      path: ':id/launch_metrics',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_overview" */ 'v2/teachers/schoolData/launchMetricsOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Overview',
      name: 'launch-metrics-overview',
      path: ':id/launch_metrics/overview',
      analyticsPageName: 'viewed_sdd_launch_metrics_home',
      analyticsPageProperties(routeParams) {
        return {
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_OVERVIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_teacher_page" */ 'v2/teachers/schoolData/launchMetricsTeacherPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Teacher View',
      name: 'launch-metrics-teacher-view',
      path: ':id/launch_metrics/:metric_name/teachers',
      analyticsPageName: 'viewed_sdd_launch_metrics_teacher_view',
      analyticsPageProperties(routeParams) {
        return {
          metricView: routeParams.metric_name,
          pagePathParams: {
            id: routeParams.id,
            metric_name: routeParams.metric_name,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_TEACHER_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_sections_page" */ 'v2/teachers/schoolData/launchMetricsSectionsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Section View',
      name: 'launch-metrics-section-view',
      path: ':id/launch_metrics/:phase/teachers/:teacher_id/sections',
      analyticsPageName: 'viewed_sdd_launch_metrics_section_view',
      analyticsPageProperties(routeParams) {
        return {
          // can't add teacher id for PII reasons
          pagePathParams: {
            id: routeParams.id,
            phase: routeParams.phase,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_sections_list_page" */ 'v2/teachers/schoolData/launchMetricsSectionListPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Section List View',
      name: 'launch-metrics-section-list-view',
      path: ':id/launch_metrics/:metric_name/teachers/:teacher_id/sections_list',
      analyticsPageName: 'viewed_sdd_launch_metrics_section_list_view',
      analyticsPageProperties(routeParams) {
        return {
          // can't add teacher id for PII reasons
          metricView: routeParams.metric_name,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_SECTIONS_LIST_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_students_page" */ 'v2/teachers/schoolData/launchMetricsStudentsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Student View',
      name: 'launch-metrics-student-view',
      path: ':id/launch_metrics/:metric_name/teachers/:teacher_id/sections/:section_id/students',
      analyticsPageName: 'viewed_sdd_launch_metrics_student_view',
      analyticsPageProperties(routeParams) {
        return {
          metricView: routeParams.metric_name,
          pagePathParams: {
            id: routeParams.id,
          },
          sectionView: routeParams.section_id,
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_site_students_page" */ 'v2/teachers/schoolData/launchMetricsStudentsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Site Student View',
      name: 'launch-metrics-site-student-view',
      path: ':id/launch_metrics/:metric_name/students',
      analyticsPageName: 'viewed_sdd_launch_metrics_student_view',
      analyticsPageProperties(routeParams) {
        return {
          metricView: routeParams.metric_name,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_launch_metrics_teacher_students_page" */ 'v2/teachers/schoolData/launchMetricsStudentsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Launch Metrics Teacher Student View',
      name: 'launch-metrics-teacher-student-view',
      path: ':id/launch_metrics/:metric_name/teachers/:teacher_id/students',
      analyticsPageName: 'viewed_sdd_launch_metrics_student_view',
      analyticsPageProperties(routeParams) {
        return {
          // can't add teacher id for PII reasons
          metricView: routeParams.metric_name,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.LAUNCH_METRICS_STUDENTS_VIEW,
        };
      },
    }),
    // END LAUNCH METRICS ROUTES
    // START ACTIVITY ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        // eslint-disable-next-line import/dynamic-import-chunkname
        import(
          // formerly v2/teachers/schoolDataLegacy/studentPlatformActivity

          /* webpackChunkName: "student_platform_activity" */ 'v2/teachers/schoolData/studentPlatformActivity'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Platform Activity',
      name: 'student-platform-activity',
      path: ':id/platform_activity/students',
      analyticsPageName: 'viewed_student_platform_activity_v1',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V1,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.STUDENT_PLATFORM_ACTIVITY,
        };
      },
    }),
    // END ACTIVITY ROUTES
  ],
  path: 'school_data',
  name: 'school-data-index',
};

const SchoolDataRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_school_data_home_looker" */ 'v2/teachers/schoolData/schoolDataEntry'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'School Data Entry',
      name: 'school-data-entry',
      path: ':id/home_entry/looker',
      analyticsPageName: 'viewed_sdd_home_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.HOME,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_school_data_home_looker" */ 'v2/teachers/schoolData/schoolDataHomePageLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'School Data Home Looker',
      name: 'school-data-home-v2-looker',
      path: ':id/home_v2/looker',
      analyticsPageName: 'viewed_sdd_home_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.HOME,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_school_data_home" */ 'v2/teachers/schoolData/schoolDataHomePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'School Data Home',
      name: 'school-data-home_v2',
      path: ':id/home_v2',
      analyticsPageName: 'viewed_sdd_home_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.HOME,
        };
      },
    }),
    // STUDENT OUTCOMES OVERVIEW LOOKER ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_grade_level_overview_looker" */ 'v2/teachers/schoolData/gradeLevelOverviewLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Overview Looker',
      name: 'grade-level-overview-v2-looker', // TODO: remove -v2 when legacy is deprecated
      path: ':id/grade_level/overview/looker',
      analyticsPageName: 'viewed_grade_level_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_teacher_overview_looker" */ 'v2/teachers/schoolData/teacherOverviewLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Overview Looker',
      name: 'teacher-overview-v2-looker', // TODO: remove -v2 when legacy is deprecated
      path: ':id/teacher/overview/looker',
      analyticsPageName: 'viewed_teacher_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_mentor_overview_looker" */ 'v2/teachers/schoolData/mentorOverviewLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Overview Looker',
      name: 'mentor-overview-v2-looker', // TODO: remove -v2 when legacy is deprecated
      path: ':id/mentor/overview/looker',
      analyticsPageName: 'viewed_mentor_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_course_overview" */ 'v2/teachers/schoolData/courseOverviewLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Overview Looker',
      name: 'course-overview-v2-looker', // TODO: remove -v2 when legacy is deprecated
      path: ':id/course/overview/looker',
      analyticsPageName: 'viewed_course_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    // STUDENT OUTCOMES OVERVIEW ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_grade_level_overview" */ 'v2/teachers/schoolData/gradeLevelOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Overview',
      name: 'grade-level-overview-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/grade_level/overview',
      analyticsPageName: 'viewed_grade_level_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_teacher_overview" */ 'v2/teachers/schoolData/teacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Overview',
      name: 'teacher-overview-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/teacher/overview',
      analyticsPageName: 'viewed_teacher_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_mentor_overview" */ 'v2/teachers/schoolData/mentorOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Overview',
      name: 'mentor-overview-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/mentor/overview',
      analyticsPageName: 'viewed_mentor_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_course_overview" */ 'v2/teachers/schoolData/courseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Overview',
      name: 'course-overview-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/course/overview',
      analyticsPageName: 'viewed_course_overview_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_OVERVIEW,
          viewType: SDD_CONSTANTS.CARD_VIEW,
        };
      },
    }),
    // STUDENT BREAKDOWN LOOKER ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "grade_level_breakdown_looker" */ 'v2/teachers/schoolData/gradeLevelBreakdownLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Breakdown Looker',
      name: 'grade-level-breakdown-looker',
      path: ':id/grade_level_breakdown/looker/:filter',
      analyticsPageName: 'viewed_grade_level_breakdown',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            grade_level: routeParams.grade_level,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teacher_breakdown_looker" */ 'v2/teachers/schoolData/teacherBreakdownLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Breakdown Looker',
      name: 'teacher-breakdown-looker',
      path: ':id/teacher_breakdown/looker/:filter',
      analyticsPageName: 'viewed_teacher_breakdown',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            // Can't add teacher_id here for PII reasons
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "mentor_breakdown_looker" */ 'v2/teachers/schoolData/mentorBreakdownLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Breakdown Looker',
      name: 'mentor-breakdown-looker',
      path: ':id/mentor_breakdown/looker/:filter',
      analyticsPageName: 'viewed_mentor_breakdown',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            // Can't add mentor_id here for PII reasons
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "course_breakdown_looker" */ 'v2/teachers/schoolData/courseBreakdownLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Breakdown Looker',
      name: 'course-breakdown-looker',
      path: ':id/course_breakdown/looker/:filter',
      analyticsPageName: 'viewed_course_breakdown',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            course_id: routeParams.course_id,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_DETAIL,
        };
      },
    }),
    // STUDENT OUTCOMES DETAILS ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/gradeLevelDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Details',
      name: 'grade-level-details-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/grade_level/:grade_level/details',
      analyticsPageName: 'viewed_grade_level_detail_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            grade_level: routeParams.grade_level,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.GRADE_LEVEL_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/teacherDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Details',
      name: 'teacher-details-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/teacher/:teacher_id/details',
      analyticsPageName: 'viewed_teacher_detail_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            // Can't add teacher_id here for PII reasons
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.TEACHER_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_details" */ 'v2/teachers/schoolData/mentorDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Details',
      name: 'mentor-details-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/mentor/:mentor_id/details',
      analyticsPageName: 'viewed_mentor_detail_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            // Can't add mentor_id here for PII reasons
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTOR_DETAIL,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_course_teacher_overview" */ 'v2/teachers/schoolData/courseDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Details',
      name: 'course-details-v2', // TODO: remove -v2 when legacy is deprecated
      path: ':id/course/:course_id/details',
      analyticsPageName: 'viewed_course_detail_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            course_id: routeParams.course_id,
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.COURSE_DETAIL,
        };
      },
    }),
    // IMPLEMENTATION METRICS LOOKER ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/studentPlatformActivityLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Platform Activity Implementation Metrics Looker',
      name: 'implementation-metrics-student-platform-activity-looker',
      path: ':id/implementation_metrics/student_platform_activity/looker',
      analyticsPageName: 'viewed_student_platform_activity',
      analyticsPageProperties(routeParams, url) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.STUDENT_PLATFORM_ACTIVITY,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/projectBasedLearningLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Project Based Learning Implementation Metrics Looker',
      name: 'implementation-metrics-project-based-learning-looker',
      path: ':id/implementation_metrics/project_based_learning/looker',
      analyticsPageName: 'viewed_project_based_learning',
      analyticsPageProperties(routeParams, url) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.PBL_USAGE,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/mentoringLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentoring Implementation Metrics Looker',
      name: 'implementation-metrics-mentoring-looker',
      path: ':id/implementation_metrics/mentoring/looker',
      analyticsPageName: 'viewed_mentoring',
      analyticsPageProperties(routeParams, url) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "mentoring_details_looker" */ 'v2/teachers/schoolData/implementation/mentoringDetailsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentoring Implementation Details Looker',
      name: 'implementation-metrics-mentoring-details-looker',
      path: ':id/implementation_metrics/mentoring/details/looker/:filter',
      analyticsPageName: 'viewed_mentoring',
      analyticsPageProperties(routeParams, url) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/selfDirectedLearningLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Self Directed Learning Implementation Metrics Looker',
      name: 'implementation-metrics-self-directed-learning-looker',
      path: ':id/implementation_metrics/self_directed_learning/looker',
      analyticsPageName: 'viewed_self_directed_learning',
      analyticsPageProperties(routeParams, url) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.SDL_USAGE,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/gradeLevelOverviewMetricsLooker'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Implementation Metrics Overview Looker',
      name: 'implementation-metrics-grade-level-overview-looker',
      path: ':id/implementation_metrics/grade_level_overview/:submetric_group/looker',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_grade_level_v2';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'gradeLevel',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    // IMPLEMENTATION METRICS ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/gradeLevelOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Grade Level Implementation Metrics Overview',
      name: 'implementation-metrics-grade-level-overview',
      path: ':id/implementation_metrics/grade_level_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_grade_level_v2';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'gradeLevel',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/teacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Implementation Metrics Overview',
      name: 'implementation-metrics-teacher-overview',
      path: ':id/implementation_metrics/teacher_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_teacher_v2';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'teacher',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/teacherCourseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Course Implementation Metrics Overview',
      name: 'implementation-metrics-teacher-course-overview',
      path: ':id/implementation_metrics/teacher_overview/:teacher_id/course_overview/:submetric_group',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/mentorOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Implementation Metrics Overview',
      name: 'implementation-metrics-mentor-overview',
      path: ':id/implementation_metrics/mentor_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_mentor_v2';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'mentor',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/courseOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Implementation Metrics Overview',
      name: 'implementation-metrics-course-overview',
      path: ':id/implementation_metrics/course_overview/:submetric_group',
      analyticsPageName: (url) => {
        const submetricGroup = getImplementationSubmetric(url.pathname);
        return 'viewed_sdd_' + submetricGroup + '_course_v2';
      },
      analyticsPageProperties(routeParams, url) {
        const surface = getImplementationSubmetricSurface(
          url.pathname,
          'course',
        );
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
            submetric_group: routeParams.submetric_group,
          },
          surface: surface,
        };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/courseTeacherOverview'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Teacher Implementation Metrics Overview',
      name: 'implementation-metrics-course-teacher-overview',
      path: ':id/implementation_metrics/course_overview/:course_id/teacher_overview/:submetric_group',
    }),
    // IMPLEMENTATION METRICS MENTORING DATA ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_signals_usage" */ 'v2/teachers/schoolData/implementation/mentorDetails'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentor Mentoring Data Details',
      name: 'implementation-metrics-mentoring-mentor-details',
      path: ':id/implementation_metrics/mentor_details/:mentor_id',
      analyticsPageName: 'viewed_sdd_mentoring_mentor_detail_v2',
      analyticsPageProperties(routeParams) {
        return {
          // Can't add mentor_id here for PII reasons
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.MENTORING_MENTOR_DETAIL,
        };
      },
    }),
    // START ACTIVITY ROUTES
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "student_platform_activity" */ 'v2/teachers/schoolData/studentPlatformActivity'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Platform Activity',
      name: 'student-platform-activity-metrics',
      path: ':id/platform_activity_metrics/students',
      analyticsPageName: 'viewed_student_platform_activity_v2',
      analyticsPageProperties(routeParams) {
        return {
          dashboardVersion: SDD_CONSTANTS.V2,
          pagePathParams: {
            id: routeParams.id,
          },
          surface: SDD_CONSTANTS.STUDENT_PLATFORM_ACTIVITY,
        };
      },
    }),
    // END ACTIVITY ROUTES
  ],
  path: 'school_data',
  name: 'school-data-index',
};

const LibraryRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_library_projects_concept_units" */ 'v2/teachers/library/projects/libraryProjectPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Library of Projects',
      name: 'library-projects',
      path: 'projects',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_library_projects_concept_units" */ 'v2/teachers/library/conceptUnits/libraryConceptUnitPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Library of Math Units',
      name: 'library-concept-units',
      path: 'concept_units',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_library_focus_areas" */ 'v2/teachers/library/focusAreas/libraryFocusAreaPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Library of Focus Areas',
      name: 'library-focus-areas',
      path: 'focus_areas',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_library_courses" */ 'v2/teachers/library/courses/libraryCoursePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Library of Courses',
      name: 'library-courses',
      path: 'courses',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_library_index" */ 'v2/teachers/library/indexHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Library of Courses',
      name: 'library-index',
      path: 'index',
    }),
  ],
  label: 'Library',
  name: 'library',
  path: 'library',
};

const StudentProfileRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_mentee_snapshot" */ 'v2/teachers/mentoring/menteeSnapshot'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Mentee Snapshot',
      name: 'mentee-snapshot',
      path: 'mentee_snapshot',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_students_progress" */ 'v2/teachers/sdl/studentProgressPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Progress',
      name: 'student-progress',
      path: 'progress',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_students_progress" */ 'v2/teachers/sdl/studentProgressPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Progress for Year',
      name: 'student-progress-for-year',
      path: 'progress/year/:filter',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_student_week" */ 'v2/teachers/sdl/studentWeekPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Week',
      name: 'student-week',
      path: 'week',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_student_year" */ 'v2/teachers/sdl/studentYearPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Year',
      name: 'student-year',
      path: 'year/:filter',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_student_foundations" */ 'v2/teachers/foundations/studentFoundationsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Student Foundations',
      name: 'student-foundations',
      path: 'foundations',
    }),
  ],
  label: 'Student Overview',
  name: 'student-overview',
  path: 'students/:studentId',
};

// Base Curriculum Routes for authors
const BaseCurriculumRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_base_curriculum_reports" */ 'v2/teachers/baseCurriculum/reportsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'base-curriculum-reports',
      path: 'base_curriculum_reports',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_force_propagate" */ 'v2/teachers/baseCurriculum/forcePropagatePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'force-propagate',
      path: 'force_propagate',
    }),
  ],
});

const UrlAllowlistRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_url_allowlist" */ 'v2/teachers/curriculumReports/urlAllowlistPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'url-allowlist',
  path: 'url_allowlist',
});

const PlatformDocsMigrationRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_platform_docs_migration" */ 'v2/teachers/platformDocsMigrationComparisonPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: `Migration Individual ${platformDocTerms.docName()}`,
      path: 'curriculum/compare',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_platform_docs_migration_comparison" */ 'v2/teachers/platformDocsMigrationComparisonPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: `Migration Individual ${platformDocTerms.docName()}`,
      path: 'google/compare',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_platform_docs_migation" */ 'v2/teachers/platformDocsMigrationPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
  }),
  label: `${platformDocTerms.docName({plural: true})} Migration`,
  name: 'platform_docs_migration',
  path: 'platform_docs_migration',
});

// Announcements
const AnnouncementRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "announcements_index" */ 'v2/teachers/announcements'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'announcements-index',
  }),
  label: 'Announcements',
  name: 'announcements',
  path: 'announcements',
};

const TeacherRoutes = {
  childRoutes: [
    AnnouncementRoutes,
    BaseCurriculumRoutes,
    UrlAllowlistRoute,
    ProjectRoutes,
    FocusAreaRoutes,
    LibraryRoutes,
    PlatformDocsMigrationRoutes,
    StudentProfileRoutes,
  ],
  name: 'teachers-v3',
  path: 'teacher',
};

const RubricRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_rubric_index" */ 'v2/rubrics/teacherRubricPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'rubric-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_rubric_dimension" */ 'v2/rubrics/teacherRubricDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Rubric Dimension',
      name: 'rubric-dimension',
      path: 'dimension/:dimensionId',
    }),
  ],
  label: 'Rubric',
  name: 'rubric',
  path: 'rubric',
};

const CourseRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_teacher_courses" */ 'v2/teachers/courses/teacherCoursesHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Teacher Courses',
      name: 'teacher-courses',
      path: 'my_courses',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_courses_home" */ 'v2/teachers/courses/coursesHome/coursesHomePage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Courses',
      name: 'courses-home',
      path: 'home',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_course" */ 'v2/teachers/courses/courseHandler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      analyticsPageProperties(routeParams) {
        return {pagePathParams: {courseId: routeParams.courseId}};
      },
      label: 'Course Page',
      name: 'course',
      path: ':courseId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_edit_course" */ 'v2/teachers/courses/courseEditPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Edit Page',
      name: 'edit-course',
      path: ':courseId/edit',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_edit_course" */ 'v2/teachers/courses/CoursePlan/coursePlanWizard'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'New Course Plan',
      name: 'course-plan-new',
      path: ':courseId/course_plan/new',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_edit_course" */ 'v2/teachers/courses/CoursePlan/coursePlanCreated'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Course Plan Created',
      name: 'course-plan-created',
      path: ':courseId/course_plan/created',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_edit_course" */ 'v2/teachers/courses/CoursePlan/viewCoursePlan'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'View Course Plan',
      name: 'course-plan',
      path: ':courseId/course_plan',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_edit_course" */ 'v2/teachers/courses/CoursePlan/coursePlanWizard'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Edit Course Plan',
      name: 'course-plan-edit',
      path: ':courseId/course_plan/edit',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "teachers_curriculum_index" */ 'v2/teachers/curriculum/curriculumHandler'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    onChange(prevState, nextState, replace, done) {
      fetchDataForRoute(nextState, replace, done);
    },
    label: 'Curriculum Grid',
    name: 'curriculum-index',
  }),
  label: 'Curriculum',
  name: 'curriculum',
  path: 'courses',
};

const DashboardRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_dashboard" */ 'v2/teachers/dashboard/handler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Dashboard',
  name: 'dashboard',
  path: 'dashboard',
});

const WhatsNewTabRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_whats_new_tab" */ 'v2/teachers/whatsnew/views/teacherWhatsNewPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: "What's New Dashboard",
  name: 'whats_new_tab',
  path: 'whats_new/:tab',
});

const WhatsNewRedirectRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_whats_new_redirect" */ 'v2/teachers/whatsnew/views/teacherWhatsNewPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'whats_new_redirect',
  path: 'whats_new',
});

const CheckForUnderstandingRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_check_for_understanding" */ 'v2/teachers/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Check For Understanding',
  name: 'check-for-understanding',
  path: '/check_for_understanding/:activityId',
});

const PreAssessmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_pre_assessment" */ 'v2/teachers/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Pre-assessment',
  name: 'pre-assessment',
  path: '/pre_assessment/:activityId/fp/:projectAssetId',
});

const EndOfUnitRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_end_of_unit_assessment" */ 'v2/teachers/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'End of Unit Assessment',
  name: 'end-of-unit-assessment',
  path: '/end_of_unit_assessment/:activityId/fp/:projectAssetId',
});

const EndOfUnitReviewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_end_of_unit_assessment_review" */ 'v2/students/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'End of Unit Assessment Review',
  name: 'end-of-unit-assessment-review',
  path: '/end_of_unit_assessment/review/:activityId/fp/:projectAssignmentAssetId',
});

const EmbeddableLearnosityAssessmentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_doc_embeddable_assessment" */ 'v2/teachers/learnosityAssessmentPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Doc Embeddable Learnosity Assessment',
  name: 'doc-embeddable-assessment',
  path: '/doc_embeddable_assessment/:activityId',
});

const ErrorRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "teachers_error" */ 'v2/teachers/errorHandler')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Error Page',
  name: 'error',
  path: 'error',
});

const GltRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    requireCurriculumOverviewComponent(done);
  },
  label: 'Grade Level Team',
  name: 'glt',
  path: 'glt/:grade',
});

const SubjectRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    requireCurriculumOverviewComponent(done);
  },
  label: 'Subjects',
  name: 'subject',
  path: 'subjects/:subjectId',
});

// Assessments
const AssessmentRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_course_assessments_overview" */ 'v2/teachers/assessments/common/assessmentOverviewPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'course-assessments-overview',
      path: 'overview/course/:courseId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_assessments_overview" */ 'v2/teachers/assessments/common/assessmentOverviewPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'assessments-overview',
      path: 'overview/:filter',
      analyticsPageName: (url) => {
        return url.pathname.includes('mentees')
          ? 'assessments-overview'
          : 'student_groups_assessments';
      },
      analyticsPageProperties(routeParams, url) {
        const {filter} = routeParams;
        return url.pathname.includes('mentees')
          ? {}
          : {
              eventTarget: filter.startsWith('group')
                ? SDD_CONSTANTS.CUSTOM_GROUP_TARGET
                : SDD_CONSTANTS.GRADE_LEVEL_GROUP_TARGET,
              productArea: SDD_CONSTANTS.EDUCATOR_DATA_TOOL,
              surface: SDD_CONSTANTS.STUDENT_GROUPS_ASSESSMENTS,
              targetType: SDD_CONSTANTS.STUDENT_GROUP_TYPE,
              targetName: filter.substr(filter.indexOf('-') + 1),
            };
      },
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_show_assessment_take" */ 'v2/teachers/assessments/show/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'show_assessment_take',
      path: 'take/:assessmentTakeId',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_print_assessment_take" */ 'v2/teachers/assessments/show/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'print_assessment_take',
      path: 'take/:assessmentTakeId/print',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_show_assessment_take_for_teacher_input" */ 'v2/teachers/assessments/show/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'show_assessment_take_for_teacher_input',
      path: 'take/:assessmentTakeId/input',
    }),
  ],
  label: 'Assessments',
  name: 'assessments',
  path: 'assessments',
};

const GoalsRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_course_goals_overview" */ 'v2/teachers/goals/common/goalsOverviewPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'course-goals-overview',
      path: 'overview/course/:courseId(/:dateType)',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_goals_overview" */ 'v2/teachers/goals/common/goalsOverviewPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'goals-overview',
      path: 'overview/:filter(/:dateType)',
      analyticsPageName: (url) => {
        return url.pathname.includes('mentees')
          ? 'goals-overview'
          : 'student_groups_goals';
      },
      analyticsPageProperties(routeParams, url) {
        const {filter} = routeParams;
        return url.pathname.includes('mentees')
          ? {}
          : {
              eventTarget: filter.startsWith('group')
                ? SDD_CONSTANTS.CUSTOM_GROUP_TARGET
                : SDD_CONSTANTS.GRADE_LEVEL_GROUP_TARGET,
              productArea: SDD_CONSTANTS.EDUCATOR_DATA_TOOL,
              surface: SDD_CONSTANTS.STUDENT_GROUPS_GOALS,
              targetType: SDD_CONSTANTS.STUDENT_GROUP_TYPE,
              targetName: filter.substr(filter.indexOf('-') + 1),
            };
      },
    }),
  ],
  label: 'Goals',
  name: 'goals',
  path: 'goals',
};

const TeacherTosRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_tos" */ 'v2/teachers/tos/teacherTosHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'teachers-tos',
  path: '/teachers/terms_and_conditions',
});

const ClaroTestRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_claro_test" */ 'v2/teachers/claroExample'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Claro Test Page',
  name: 'claro_test',
  path: '/teachers/claro-test',
});

const PlatformDocsDriveRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "platform_docs_drive_search" */ 'v2/teachers/platform_doc_drive/platformDocDriveSearchResultsPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Notebook Home Search',
      name: 'platform-doc-drive-search',
      path: 'search',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "platform_docs_drive_index" */ 'v2/teachers/platform_doc_drive/platformDocDriveLandingPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    name: 'platform-doc-drive-index',
  }),
  label: 'Notebooks Home',
  name: 'platform-doc-drive',
  path: 'notebooks',
};

const LearningToolRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_learning_tool" */ 'v2/teachers/learningToolPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Learning Tool',
  name: 'teacher-lti-tool',
  path: 'learning_tools/:toolName',
});

const TeacherRoutesV2 = {
  childRoutes: [
    AssessmentRoutes,
    CheckForUnderstandingRoute,
    CourseRoutes,
    DashboardRoute,
    DataRoutes,
    EmbeddableLearnosityAssessmentRoute,
    ErrorRoute,
    FoundationsCaseloadRoute,
    GltRoute,
    GoalsRoutes,
    EndOfUnitReviewRoute,
    EndOfUnitRoute,
    MentoringRoute,
    MockStudentViewRoute,
    PreAssessmentRoute,
    UserRoleSurveyViewRoute,
    SubjectRoute,
    WhatsNewRedirectRoute,
    WhatsNewTabRoute,
    LearningToolRoute,
  ],
  name: 'teachers-v2',
  path: 'v2/teacher',
};

const StudentProjectOverviewRoute = Route.createRouteFromObject({
  name: 'project_overview',
  path: '/my/projects/:projectId/overview',
});

const StudentFocusAreasRoute = Route.createRouteFromObject({
  name: 'focusArea',
  path: '/my/focusareas/:areaId',
});

// Define student routes needed for client side URL path resolution but avoid actually loading
// student handlers and components
const ViewAsStudentRoutes = {
  childRoutes: [StudentFocusAreasRoute, StudentProjectOverviewRoute],
  name: 'viewAsStudent',
  path: '/',
};

const TeachersRootRoutes: ReactRouterRootRoutesType = {
  appType: AppTypes.TEACHERS,
  childRoutes: [
    AdminRoutes,
    EducatorLabsRoute,
    EducatorToolsRoute,
    LearnRoutes,
    PlatformDocRoutes,
    PlatformDocsDriveRoutes,
    RubricRoutes,
    SchoolDataRoutes,
    SchoolDataLegacyRoutes,
    TeacherRoutes,
    TeacherRoutesV2,
    TeacherTosRoute,
    ResourcesRoutes,
    ClaroTestRoute,
    ViewAsStudentRoutes,
    EducatorLabsCourseComposerRoute,
    EducatorLabsRecommendationsRoute,
  ],
  component: TeachersApp,
  name: 'app',
  path: '/',
};

export default TeachersRootRoutes;
