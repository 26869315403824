import handleImportError from 'v2/core/routing/handleImportError';
import {Route} from 'v2/core/routing/routesWithFetchData';

import * as SDD_CONSTANTS from 'v2/teachers/schoolData/constants/schoolDataAnalytics';

const CogSkillsCourseRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_cogskills_course" */

      'v2/teachers/data/dimensions/teacherDimensionsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'cogskills-course',
  path: 'cogskills/course/:courseId',
});

const CogSkillsCourseFilteredRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_cogskills_course_filtered" */

      'v2/teachers/data/dimensions/teacherDimensionsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'cogskills-course-filtered',
  path: 'cogskills/:filter/course/:courseId',
});

const ConceptsCourseRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_concepts_course" */

      'v2/teachers/data/dimensions/teacherDimensionsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'concepts-course',
  path: 'concepts/course/:courseId',
});

const ConceptsCourseFilteredRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_concepts_course_filtered" */

      'v2/teachers/data/dimensions/teacherDimensionsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'concepts-course-filtered',
  path: 'concepts/:filter/course/:courseId',
});

const ExpeditionsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_expeditions" */

      'v2/teachers/expeditions/teacherExpeditionsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'expeditions',
  path: 'expeditions',
});

const StudentGroupsIndexRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_student_groups_index" */

      'v2/teachers/data/explore/indexPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'student-groups-index',
  path: 'student_groups',
  analyticsPageName: 'student_groups_index',
  analyticsPageProperties() {
    return {
      surface: SDD_CONSTANTS.STUDENT_GROUPS_INDEX,
      productArea: SDD_CONSTANTS.EDUCATOR_DATA_TOOL,
    };
  },
});

const StudentGroupsOverviewDataRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_student_groups_overview_data" */

      'v2/teachers/data/studentGroups/studentGroupsOverview'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'student-groups-overview-data',
  path: 'student_groups/overview_data/:filter',
  analyticsPageName: 'student_groups_overview_data',
  analyticsPageProperties(routeParams) {
    const {filter} = routeParams;
    return {
      eventTarget: filter.startsWith('group')
        ? SDD_CONSTANTS.CUSTOM_GROUP_TARGET
        : SDD_CONSTANTS.GRADE_LEVEL_GROUP_TARGET,
      productArea: SDD_CONSTANTS.EDUCATOR_DATA_TOOL,
      surface: SDD_CONSTANTS.STUDENT_GROUPS_OVERVIEW,
      targetType: SDD_CONSTANTS.STUDENT_GROUP_TYPE,
      targetName: filter.substr(filter.indexOf('-') + 1),
    };
  },
});

const ExploreOverviewRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreOverview'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-filtered',
  path: 'explore/:filter',
  analyticsPageName: (url) => {
    return url.pathname.includes('mentees')
      ? 'explore-filtered'
      : 'student_groups_students';
  },
  analyticsPageProperties(routeParams, url) {
    const {filter} = routeParams;
    return url.pathname.includes('mentees')
      ? {}
      : {
          eventTarget: filter.startsWith('group')
            ? SDD_CONSTANTS.CUSTOM_GROUP_TARGET
            : SDD_CONSTANTS.GRADE_LEVEL_GROUP_TARGET,
          productArea: SDD_CONSTANTS.EDUCATOR_DATA_TOOL,
          surface: SDD_CONSTANTS.STUDENT_GROUPS_STUDENTS,
          targetType: SDD_CONSTANTS.STUDENT_GROUP_TYPE,
          targetName: filter.substr(filter.indexOf('-') + 1),
        };
  },
});

const ExploreCogSkillsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreCogSkills'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-cogskills',
  path: 'explore/:filter/cogskills',
});

const ExploreConceptsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreConcepts'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-concepts',
  path: 'explore/:filter/concepts',
});

const ExplorePowerFocusAreasRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/explorePowerFocusAreas'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-focusareas',
  path: 'explore/:filter/powerfocusareas',
});

const ExploreAdditionalFocusAreasRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreAdditionalFocusAreas'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-additionalfocusareas',
  path: 'explore/:filter/additionalfocusareas',
});

const ExploreIncompletesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreIncompletes'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-incompletes',
  path: 'explore/:filter/incompletes',
});

const ExploreOverduesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_explore" */

      'v2/teachers/data/explore/exploreOverdues'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'explore-overdues',
  path: 'explore/:filter/overdues',
});

const FocusAreasCourseRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_focusareas_course" */

      'v2/teachers/data/focusareas/teacherFocusAreasPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'focusareas-course',
  path: 'focusareas/course/:courseId',
});

const FocusAreasCourseFilterRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_focusareas_course_filtered" */

      'v2/teachers/data/focusareas/teacherFocusAreasPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'focusareas-course-filtered',
  path: 'focusareas/:filter/course/:courseId',
});

const GradebookRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_gradebook_course" */

      'v2/teachers/data/gradebook/teacherGradebookPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'gradebook-course',
  path: 'gradebook/course/:courseId',
});

const ObjectivesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_objectives_course" */

      'v2/teachers/data/objectives/teacherObjectivesPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'objectives-course',
  path: 'objectives/:focusAreaId/course/:courseId',
});

const ObjectivesFilterRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_objectives_course_filtered" */

      'v2/teachers/data/objectives/teacherObjectivesPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'objectives-course-filtered',
  path: 'objectives/:filter/:focusAreaId/course/:courseId',
});

const OverduesCourseRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_overdues_course" */

      'v2/teachers/data/overdues/teacherOverduesPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'overdues-course',
  path: 'overdues/course/:courseId',
});

const OverduesCourseFilteredRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "teachers_overdues_course_filtered" */

      'v2/teachers/data/overdues/teacherOverduesPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'overdues-course-filtered',
  path: 'overdues/:filter/course/:courseId',
});

const exported = {
  childRoutes: [
    CogSkillsCourseRoute,
    CogSkillsCourseFilteredRoute,
    ConceptsCourseRoute,
    ConceptsCourseFilteredRoute,
    ExpeditionsRoute,
    StudentGroupsIndexRoute,
    StudentGroupsOverviewDataRoute,
    ExploreAdditionalFocusAreasRoute,
    ExploreOverviewRoute,
    ExploreCogSkillsRoute,
    ExploreConceptsRoute,
    ExploreIncompletesRoute,
    ExploreOverduesRoute,
    ExplorePowerFocusAreasRoute,
    FocusAreasCourseRoute,
    FocusAreasCourseFilterRoute,
    GradebookRoute,
    ObjectivesRoute,
    ObjectivesFilterRoute,
    OverduesCourseRoute,
    OverduesCourseFilteredRoute,
  ],

  label: 'Teacher Data',
  name: 'data',
  path: 'data',
};

export default exported;

export const {childRoutes, label, name, path} = exported;
