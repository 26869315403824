import {UNKNOWN} from 'v2/core/logging/analytics/constants';

// Set of constants to be used in tracking.

// productArea
export const EDUCATOR_DATA_TOOL = 'educator_data_tool';

// eventType
export const HOVER_CARD = 'hover_card';

// dashboardVersion
export const V1 = 'v1';
export const V2 = 'v2';

export const LAUNCH_METRICS = 'launch_metrics';
export const STEADY_STATE = 'steady_state';

// viewType
export const CARD_VIEW = 'card_view';
export const LIST_VIEW = 'list_view';

// surface
export const HOME = 'sdd_home';
export const STUDENT_PLATFORM_ACTIVITY = 'sdd_student_platform_activity';

export const STUDENT_OUTCOMES_OVERVIEW = 'sdd_student_outcomes_overview';

export const GRADE_LEVEL_OVERVIEW = 'sdd_grade_level_overview';
export const TEACHER_OVERVIEW = 'sdd_teacher_overview';
export const MENTOR_OVERVIEW = 'sdd_mentor_overview';
export const COURSE_OVERVIEW = 'sdd_course_overview';

export const GRADE_LEVEL_DETAIL = 'sdd_grade_level_detail';
export const TEACHER_DETAIL = 'sdd_teacher_detail';
export const MENTOR_DETAIL = 'sdd_mentor_detail';
export const COURSE_DETAIL = 'sdd_course_detail';
export const COURSE_TEACHER_DETAIL = 'sdd_course_teacher_detail';

export const SDL_GRADE_LEVEL = 'sdd_self_directed_learning_grade_level';
export const SDL_TEACHER = 'sdd_self_directed_learning_teacher';
export const SDL_MENTOR = 'sdd_self_directed_learning_mentor';
export const SDL_COURSE = 'sdd_self_directed_learning_course';
export const SDL_COURSE_TEACHER = 'sdd_self_directed_learning_course_teacher';
export const SDL_TEACHER_COURSE = 'sdd_self_directed_learning_teacher_course';

export const PBL_GRADE_LEVEL = 'sdd_project_based_learning_grade_level';
export const PBL_TEACHER = 'sdd_project_based_learning_teacher';
export const PBL_MENTOR = 'sdd_project_based_learning_mentor';
export const PBL_COURSE = 'sdd_project_based_learning_course';
export const PBL_COURSE_TEACHER = 'sdd_project_based_learning_course_teacher';
export const PBL_TEACHER_COURSE = 'sdd_project_based_learning_teacher_course';

export const MENTORING_GRADE_LEVEL = 'sdd_mentoring_grade_level';
export const MENTORING_MENTOR = 'sdd_mentoring_mentor';
export const MENTORING_MENTOR_DETAIL = 'sdd_mentoring_mentor_detail';

export const SDL_USAGE = 'sdl_usage';
export const PBL_USAGE = 'pbl_usage';
export const MENTORING = 'mentoring';

export const STUDENT_GROUPS_INDEX = 'student_groups_index';
export const STUDENT_GROUPS_STUDENTS = 'student_groups_students';
export const STUDENT_GROUPS_ASSESSMENTS = 'student_groups_assessments';
export const STUDENT_GROUPS_GOALS = 'student_groups_goals';
export const STUDENT_GROUPS_OVERVIEW = 'student_groups_overview_data';
export const STUDENT_GROUPS_MANAGE = 'manage_student_groups';
export const STUDENT_GROUPS_CREATE_FLOW = 'manage_student_groups_create_flow';
export const STUDENT_GROUPS_EDIT_FLOW = 'manage_student_groups_edit_flow';

export const LAUNCH_METRICS_OVERVIEW = 'sdd_launch_metrics_home';
export const LAUNCH_METRICS_TEACHER_VIEW = 'sdd_launch_metrics_teacher_view';
export const LAUNCH_METRICS_SECTIONS_VIEW = 'sdd_launch_metrics_section_view';
export const LAUNCH_METRICS_STUDENTS_VIEW = 'sdd_launch_metrics_student_view';
export const LAUNCH_METRICS_SECTIONS_LIST_VIEW =
  'sdd_launch_metrics_sections_list_view';

export const IMPLEMENTATION_METRICS_SURFACES = {
  gradeLevel: {
    [SDL_USAGE]: SDL_GRADE_LEVEL,
    [PBL_USAGE]: PBL_GRADE_LEVEL,
    [MENTORING]: MENTORING_GRADE_LEVEL,
  },
  teacher: {
    [SDL_USAGE]: SDL_TEACHER,
    [PBL_USAGE]: PBL_TEACHER,
    [MENTORING]: UNKNOWN,
  },
  mentor: {
    [SDL_USAGE]: SDL_MENTOR,
    [PBL_USAGE]: PBL_MENTOR,
    [MENTORING]: MENTORING_MENTOR,
  },
  course: {
    [SDL_USAGE]: SDL_COURSE,
    [PBL_USAGE]: PBL_COURSE,
    [MENTORING]: UNKNOWN,
  },
  courseTeacher: {
    [SDL_USAGE]: SDL_COURSE_TEACHER,
    [PBL_USAGE]: PBL_COURSE_TEACHER,
    [MENTORING]: UNKNOWN,
  },
  teacherCourse: {
    [SDL_USAGE]: SDL_TEACHER_COURSE,
    [PBL_USAGE]: PBL_TEACHER_COURSE,
    [MENTORING]: UNKNOWN,
  },
} as const;

// eventTarget
export const GRADE_LEVEL_GROUP_TARGET = 'grade_level';
export const CUSTOM_GROUP_TARGET = 'custom_group';
export const SECTION_GROUP_TARGET = 'section';
export const STUDENT_LIST_GROUP_TARGET = 'student_list';
export const LM_TEACHER_DETAIL = 'teachers_detail';
export const LM_STUDENT_DETAIL = 'students_detail';
export const LM_SECTIONS_DETAIL = 'sections_detail';
export const LM_SECTION_LIST_DETAIL = 'section_list_detail';
export const ON_TRACK = 'on_track';
export const OFF_TRACK = 'off_track';
export const LAUNCH_METRIC = 'launch_metric';
export const LAUNCH_METRICS_GUIDE = 'launch_metrics_guide';
export const SECTIONS = 'sections';
export const STATUS = 'status';
export const GRADE = 'grade';

// targetType
export const STUDENT_GROUP_TYPE = 'student_group';
