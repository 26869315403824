// codeowner: frontend_infra
import {Component} from 'react';
import App from 'v2/core/components/app';
import type {AppProps} from 'v2/core/components/app';

// The top level App component for parents pages
class TrajectParentsApp extends Component<AppProps> {
  render() {
    return (
      <App
        className="parents"
        params={this.props.params}
        routes={this.props.routes}
      >
        {this.props.children}
      </App>
    );
  }
}

export default TrajectParentsApp;
