import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {Route} from 'v2/core/routing/routesWithFetchData';
import DemoApp from 'v2/demo/app';

import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

const DemoRootRoutes: ReactRouterRootRoutesType = {
  appType: AppTypes.DEMO,
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(/* webpackChunkName: "demo_home" */ 'v2/demo/homeHandler')
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'demo_home',
      path: 'demo/home',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "demo_input_email" */ 'v2/demo/inputEmailPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'input_email',
      path: 'demo/input_email',
    }),
  ],
  component: DemoApp,
  name: 'app',
  path: '/',
};

export default DemoRootRoutes;
