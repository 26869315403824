// codeowner: trust
import {Text, Link} from '@chanzuckerberg/eds';
import EmptyState from 'v2/core/components/emptyState';

const MaliciousLinkWarning = () => {
  return (
    <EmptyState
      align="center"
      headerText="We've detected an unsafe link"
      verticalAlign="center"
    >
      <Text size="lg">
        You can't complete this action because this link has been identified as
        potentially harmful. Please visit our{' '}
        <Link href="https://help.summitlearning.org/hc/en-us/articles/9948501158291">
          Help Center
        </Link>{' '}
        to learn more.
      </Text>
    </EmptyState>
  );
};

export default MaliciousLinkWarning;
