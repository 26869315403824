// codeowner: trust
import {Button, Text} from '@chanzuckerberg/eds';
import * as URI from 'uri-js';
import EmptyState from 'v2/core/components/emptyState';

type Props = {
  url: string;
};

const UnableToDetermineRisk = ({url}: Props) => {
  const hostname = URI.parse(url).host || 'this link';
  return (
    <div className="app-empty-state-center">
      <img
        alt="Magnifying glass that says 'Uh Oh' in the middle, on a green shapeless background"
        src="https://cdn.summitlearning.org/images/no_search_results.png"
      />
      <EmptyState
        align="center"
        headerText={`The connection to ${hostname} is not secure.`}
        verticalAlign="center"
      >
        <Text size="md">
          You are seeing this warning because the site does not support HTTPS.{' '}
          <Button onClick={() => location.replace(url)} variant="link">
            Continue anyway
          </Button>
        </Text>
      </EmptyState>
    </div>
  );
};

export default UnableToDetermineRisk;
