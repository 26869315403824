// codeowner: notebooks
// Similar to `v2/core/util/docProviderTerms.js`, this file defines the
// user-facing terms for Platform Doc.

function docName(options?: {plural: boolean}): string {
  return options && options.plural ? 'Notebooks' : 'Notebook';
}

const exported = {
  docName,
};

export default exported;
