import cx from 'classnames';
import {Component} from 'react';
import type {$TSFixMeEmpty} from '../util/flowCompat';
import Button from 'v2/core/EDSCandidates/button'; // eslint-disable-line no-restricted-imports

import type {BsSizes} from 'v2/core/constants/bootstrap';

const LoadingIndicatorIcon = (props: {bsSize: BsSizes | $TSFixMeEmpty}) => {
  const {bsSize} = props;
  return <div className={cx('loading-indicator-icon', bsSize)} />;
};

type LoadingIndicatorProps = {
  /**
   * ReactBootstrap's `<Button>` `bsSize` prop
   */
  bsSize?: BsSizes;
  className?: string;
};

/**
 * ## LoadingIndicator
 *
 * Visual indicator that something is happening, usually an ajax request. Should
 * generally be placed next to the element that triggered the request, most
 * likely a button or link of some sort.
 */
class LoadingIndicator extends Component<LoadingIndicatorProps> {
  render() {
    const {bsSize} = this.props;
    return (
      <Button
        aria-label="Loading"
        bsSize={bsSize}
        bsStyle="link"
        className={cx('loading-indicator', this.props.className)}
        disabled
      >
        <LoadingIndicatorIcon bsSize={bsSize} />
      </Button>
    );
  }
}

export default LoadingIndicator;
