import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {Route} from 'v2/core/routing/routesWithFetchData';
import LoginApp from 'v2/login/app';
import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

const LoginInterstitial2Route = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "login_interstitial_2" */ 'v2/login/components/loginInterstitial2'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'interstitial2',
  path: '/login/interstitial2',
});

const OneDriveInitRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "login_one_drive_init" */ 'v2/login/components/oneDriveInit'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'oneDriveInit',
  path: '/login/one_drive_init',
});

const LoginRootRoutes: ReactRouterRootRoutesType = {
  appType: AppTypes.LOGIN,
  childRoutes: [LoginInterstitial2Route, OneDriveInitRoute],
  component: LoginApp,
  name: 'app',
  path: '/',
};

export default LoginRootRoutes;
