// codeowner: trust
import {createElement} from 'react';
import ReactDOM from 'react-dom';
import init from 'v2/core/init';
import TrajectRedirectApp from 'v2/redirect/redirectApp';

init();

const container = document.getElementById('container-root');

if (container) {
  ReactDOM.render(createElement(TrajectRedirectApp), container);
}
