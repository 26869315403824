import AppTypes from 'v2/core/constants/appTypes';
import handleImportError from 'v2/core/routing/handleImportError';
import {IndexRoute, Route} from 'v2/core/routing/routesWithFetchData';
import TrajectParentsApp from 'v2/parents/app';
import PlatformDocRoutes from 'v2/platform_docs/platformDocRoutes';

import type {ReactRouterRootRoutesType} from 'v2/types/routeTypes';

const ParentLoginRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_login" */ 'v2/parents/login/parentLoginPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parent-login',
  path: 'parents/login',
});

const ParentLoginTestRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_login" */ 'v2/parents/login/parentLoginPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parent-login',
  path: 'parents/login',
});

const ParentDashboardRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_dashboard" */ 'v2/parents/dashboard/dashboardPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Dashboard',
  name: 'parent-dashboard',
  path: 'parents/dashboard',
});

const ParentRequireNewPasswordRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_new_password" */ 'v2/parents/requireNewPassword/requireNewPasswordPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'New Password',
  name: 'parent-require-new-password',
  path: 'parents/require_new_password',
});

const ParentChangePasswordRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_change_password" */ 'v2/parents/changepassword/changePasswordPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Change Password',
  name: 'parent-change-password',
  path: 'parents/change_password',
});

const ParentChangeInfoRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_change_info" */ 'v2/parents/changeinfo/changeInfoPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Change Info',
  name: 'parent-change-info',
  path: 'parents/change_info',
});

const ParentErrorRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "parents_error" */ 'v2/parents/errorPage')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'error',
  path: 'parents/error',
});

const ParentStudentsDefaultRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_students_default" */ 'v2/parents/homePage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parent-students-default',
  path: 'parents/home/students',
});

const ParentStudentsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_students" */ 'v2/parents/homePage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parent-students',
  path: 'parents/home/students/:studentId',
});

const ParentResourcesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_parent_resources" */ 'v2/parents/resourcesPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parent-resources',
  path: 'parents/resources',
});

const ParentTosRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "parents_tos" */ 'v2/parents/tos/parentTosHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'parents-tos',
  path: 'parents/terms_and_conditions',
});

const RubricRoutes = {
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "rubric_index" */ 'v2/rubrics/parentRubricPage'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
    label: 'Rubric',
    name: 'rubric-index',
  }),
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "rubric_dimension" */ 'v2/rubrics/parentRubricDimensionPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Rubric Dimension',
      name: 'rubric-dimension',
      path: 'dimension/:dimensionId',
    }),
  ],
  name: 'rubric',
  path: 'rubric',
};

// TODO: Redirect route from sign_in to login

const ParentsRootRoutes: ReactRouterRootRoutesType = {
  appType: AppTypes.PARENTS,
  childRoutes: [
    ParentLoginRoute,
    ParentLoginTestRoute,
    ParentDashboardRoute,
    ParentRequireNewPasswordRoute,
    ParentChangePasswordRoute,
    ParentChangeInfoRoute,
    ParentErrorRoute,
    ParentStudentsRoute,
    ParentStudentsDefaultRoute,
    ParentResourcesRoute,
    ParentTosRoute,
    PlatformDocRoutes,
    RubricRoutes,
  ],
  component: TrajectParentsApp,
  name: 'app',
  path: '/',
};

export default ParentsRootRoutes;
