import {Heading} from '@chanzuckerberg/eds';
import cx from 'classnames';
import * as React from 'react';
type EmptyStateProps = {
  /**
   * Text alignment
   */
  align: 'center' | 'left' | 'right';
  children?: React.ReactNode;
  className?: string;
  /**
   * Text displayed in header
   */
  headerText?: React.ReactElement | string;
  /**
   * Text size
   */
  size: 'large' | 'small';
  /**
   * Vertical alignment of text
   */
  verticalAlign: 'bottom' | 'center' | 'top';
};

/**
 * ## EmptyState
 *
 * Provides a structured way to define and style empty states across the app.
 */
class EmptyState extends React.Component<EmptyStateProps> {
  static defaultProps = {
    align: 'left',
    size: 'large',
    verticalAlign: 'top',
  };

  render() {
    const {align, children, className, headerText, size, verticalAlign} =
      this.props;

    let content = children;
    if (headerText) {
      content = (
        <div>
          <Heading as="h1" size="h3" variant="neutral-strong">
            {headerText}
          </Heading>
          {children}
        </div>
      );
    }

    return (
      <div
        className={cx(
          'app-empty-state',
          {
            'app-empty-state-center': align === 'center',
            'app-empty-state-right': align === 'right',
            'app-empty-state-sm': size === 'small',
            'app-empty-state-bottom': verticalAlign === 'bottom',
            'app-empty-state-vertical-center': verticalAlign === 'center',
          },
          className,
        )}
      >
        {content}
      </div>
    );
  }
}

export default EmptyState;
