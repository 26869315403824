import {Component} from 'react';
import App from 'v2/core/components/app';
import type {AppProps} from 'v2/core/components/app';

/**
 * Guest section of the PLP that contains public-facing content and does not
 * require a user to be logged in.
 */
class TrajectGuestApp extends Component<AppProps> {
  render() {
    return (
      <App
        className="guest"
        params={this.props.params}
        routes={this.props.routes}
      >
        {this.props.children}
      </App>
    );
  }
}

export default TrajectGuestApp;
