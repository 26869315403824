import handleImportError from 'v2/core/routing/handleImportError';
import {IndexRoute, Route} from 'v2/core/routing/routesWithFetchData';

import type {ReactRouterRouteObjectType} from 'v2/types/routeTypes';

const UserSearchRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_user_search" */ 'v2/admin/userSearch'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'User Search Show',
      name: 'user_search_show',
      path: ':id',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(/* webpackChunkName: "admin_user_search" */ 'v2/admin/userSearch')
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
  }),
  label: 'User Search',
  name: 'user_search',
  path: 'user_search',
};

// Site-Specific Routes (sites/:siteId)
const SiteSettingsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_settings" */ 'v2/admin/siteSettings'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Settings',
  name: 'site_settings',
  path: ':siteId/settings',
});

const SiteCleverSyncsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_clever_syncs" */ 'v2/admin/siteCleverSyncsPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Clever Syncs',
  name: 'site_clever_syncs',
  path: ':siteId/clever_syncs/:id',
});

const SiteDataDownloadsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_data_downloads" */ 'v2/admin/siteDataDownloads'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Data Downloads',
  name: 'site_data_downloads',
  path: ':siteId/data_downloads',
});

const SiteDataUploadsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_data_uploads" */ 'v2/admin/siteDataUploads'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Data Uploads',
  name: 'site_data_uploads',
  path: ':siteId/data_uploads',
});

const SiteSectionsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_sections" */ 'v2/admin/siteSections'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Sections',
  name: 'site_sections',
  path: ':siteId/sections',
});

const SiteTeachersRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_teachers" */ 'v2/admin/siteTeachers'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Teacher Setup',
  name: 'site_teachers',
  path: ':siteId/teachers',
});

const SiteStudentsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_students" */ 'v2/admin/siteStudents'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Students Setup',
  name: 'site_students',
  path: ':siteId/students',
});

const ManageStudentRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_manage_student" */ 'v2/admin/manageStudents'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'manage-student',
  path: ':siteId/students/:id',
});

const SiteParentsRoute = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_site_parents" */ 'v2/admin/siteParents'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Site Parents Setup',
      name: 'site_parents',
      path: ':siteId/parents',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_site_parents_message_history" */ 'v2/admin/parents/messageHistory'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Site Parents Message History',
      name: 'site_parents_message_history',
      path: ':siteId/parents/:parentId',
    }),
  ],
});

const SiteCalendarRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_calendar" */ 'v2/admin/siteCalendar'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Calendar',
  name: 'site_calendar',
  path: ':siteId/calendar',
});

const SiteCollegesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_colleges" */ 'v2/admin/siteColleges'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Colleges Setup',
  name: 'site_colleges',
  path: ':siteId/colleges',
});

const SiteDashboardRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_getting_started" */ 'v2/admin/newSiteAdminDashboard'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Checklist',
  name: 'getting_started',
  path: ':siteId',
});

const SiteViewAsActivityRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_view_as_activity" */ 'v2/admin/siteViewAsActivity/siteViewAsActivityHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site View As Activity',
  name: 'site_view_as_activity',
  path: ':siteId/view_as_activity',
});

const SiteFocusAreaExceptionsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_site_fa_exceptions" */ 'v2/admin/siteFocusAreaExceptions'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Focus Area Exceptions',
  name: 'site_focus_area_exceptions',
  path: ':siteId/focus_area_exceptions',
});

const SiteNavigationIndexRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "admin_site_setup" */ 'v2/admin/indexHandler')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Site Navigation Index',
  name: 'site_setup',
  path: ':siteId/setup',
});

const SiteSecurityRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(/* webpackChunkName: "admin_site_security" */ 'v2/admin/security')
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: 'Security Active Sessions',
  name: 'security_active_sessions',
  path: ':siteId/security',
});

const SitePlatformDocsRoute = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_platform_docs_migation" */ 'v2/teachers/platformDocsMigrationPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Site Review Docs Conversion',
      name: 'site_review_docs',
      path: ':siteId/review_docs',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_site_review_docs" */ 'v2/teachers/platformDocsMigrationComparisonPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Platform Doc Migration One Doc',
      path: 'curriculum/compare',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "teachers_platform_docs_migation_comparison" */ 'v2/teachers/platformDocsMigrationComparisonPage'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      label: 'Platform Doc Migration One Doc',
      path: 'google/compare',
    }),
  ],
});
const SiteRoutes = Route.createRouteFromObject({
  childRoutes: [
    SiteDashboardRoute,
    SiteCalendarRoute,
    SiteCleverSyncsRoute,
    SiteCollegesRoute,
    SiteFocusAreaExceptionsRoute,
    SiteParentsRoute,
    SitePlatformDocsRoute,
    SiteSectionsRoute,
    SiteSettingsRoute,
    SiteStudentsRoute,
    ManageStudentRoute,
    SiteTeachersRoute,
    SiteNavigationIndexRoute,
    SiteDataDownloadsRoute,
    SiteDataUploadsRoute,
    SiteSecurityRoute,
    SiteViewAsActivityRoute,
  ],
  path: 'sites',
});

const PlatformDocAdminRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_platform_docs" */ 'v2/admin/platform_docs/platformDocsAdminPage'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  label: ' Platform Docs Amdin',
  name: 'platform_docs',
  path: 'platform_docs',
});

// Admin Routes
const AdminCurriculumRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_items" */ 'v2/admin/curriculum/items'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_items',
      path: 'curriculum/items/:page_num',
    }),
  ],
});

const AdminRolloutRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_rollout_feature" */ 'v2/admin/rollout/feature'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_rollout_feature',
      path: 'rollout/feature/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_rollout_feature_history" */ 'v2/admin/rollout/featureHistory'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_rollout_feature_history',
      path: 'rollout/feature/:id/history',
    }),
  ],
});

const AdminProductFeaturesRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_product_feature_create" */ 'v2/admin/product_features/update'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_product_feature_create',
      path: 'product_features/create',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_product_feature_update" */ 'v2/admin/product_features/update'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_product_feature_update',
      path: 'product_features/:id',
    }),
  ],
});

const AdminAnnouncementsRoutes = Route.createRouteFromObject({
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_announcement_new" */ 'v2/admin/announcements/update'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_announcement_new',
      path: 'announcements/new',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_announcement_edit" */ 'v2/admin/announcements/update'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_announcement_edit',
      path: 'announcements/:id/edit',
    }),
  ],
});

const AdminInfoRoutes = {
  childRoutes: [
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_assessment_take_info" */ 'v2/admin/object_info/assessment_takes/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_assessment_take_info',
      path: 'assessment_take/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_questions_info" */ 'v2/admin/object_info/questions/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_questions_info',
      path: 'items/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_course_info" */ 'v2/admin/object_info/courses/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_course_info',
      path: 'course/:id',
    }),

    // Note that focusareas are "know_dos" on the backend
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_focus_area_info" */ 'v2/admin/object_info/focusareas/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_focus_area_info',
      path: 'focusarea/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_project_info" */ 'v2/admin/object_info/projects/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_project_info',
      path: 'project/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_student_info" */ 'v2/admin/object_info/students/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_student_info',
      path: 'student/:id',
    }),
    Route.createRouteFromObject({
      getComponent(location, done) {
        import(
          /* webpackChunkName: "admin_teacher_info" */ 'v2/admin/object_info/teachers/handler'
        )
          .then(({default: Handler}) => done(null, Handler))
          .catch(handleImportError(done));
      },
      name: 'admin_teacher_info',
      path: 'teacher/:id',
    }),
  ],
  indexRoute: IndexRoute.createRouteFromObject({
    getComponent(location, done) {
      import(
        /* webpackChunkName: "admin_object_info" */ 'v2/admin/object_info/index/handler'
      )
        .then(({default: Handler}) => done(null, Handler))
        .catch(handleImportError(done));
    },
  }),
  name: 'admin_object_info',
  path: 'info',
};

const UpdateCoursesRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_update_courses" */ 'v2/admin/updateCourses'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'update-courses',
  path: 'update_courses',
});

const EditWalkthroughRichTextRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "edit_walkthrough_rich_text" */ 'v2/admin/editWalkthroughRichText'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'edit_walkthrough_rich_text',
  path: 'edit_walkthrough_rich_text',
});

const ProjectDocsRoute = Route.createRouteFromObject({
  getComponent(location, done) {
    import(
      /* webpackChunkName: "admin_support_project_docs" */ 'v2/admin/projectDocs/projectDocsHandler'
    )
      .then(({default: Handler}) => done(null, Handler))
      .catch(handleImportError(done));
  },
  name: 'support-project-docs',
  path: 'support/project_docs',
});

const AdminRoutes = {
  childRoutes: [
    AdminAnnouncementsRoutes,
    AdminCurriculumRoutes,
    AdminInfoRoutes,
    AdminProductFeaturesRoutes,
    AdminRolloutRoutes,
    EditWalkthroughRichTextRoute,
    PlatformDocAdminRoute,
    UpdateCoursesRoute,
    ProjectDocsRoute,
  ],
  name: 'admin-routes',
  path: 'admin',
};

// unlike other routes.js, this one isn't a top-level app and is just included
// in teachers/routes.js
const AdminRootRoutes: ReactRouterRouteObjectType = {
  childRoutes: [AdminRoutes, SiteRoutes, UserSearchRoutes],
  name: 'app',
  path: '/',
};

export default AdminRootRoutes;
